//@flow
import React, { memo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { type BadgeTypeEnum } from './BadgeTypeEnum';

import { Dialog, DialogContent } from '@material-ui/core';
import ProtectionBadge from './Badge';
import { getBadgeDescriptionFromBadgeType, hasApplicableTasksForBadge } from './util';
import type { AppProtectionTask } from '@dt/user-api/mobile_apps';
import { palette } from '@dt/theme';

import CardDescWindow from './CardDescWindow';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
    },
  },

  badge: {
    width: ({ width }) => width,
    height: ({ height }) => height,
  },

  description: {
    wordBreak: 'break-word',
    textAlign: 'center',

    overflow: 'hidden',
    fontSize: 12,

    margin: 10,
  },

  taskStatus: {
    fontSize: 11,
  },

  completed: {
    color: palette.green30,
  },
  notCompleted: {
    color: palette.gray35,
  },
});

type Props = {|
  type: BadgeTypeEnum,
  enabled: boolean,
  height: number,
  width: number,
  tasksDialog?: Function | null,
  tasksForBadge: $ReadOnlyArray<AppProtectionTask>,
|};

function ProtectionBadgeCard(props) {
  const { type, enabled, tasksForBadge, tasksDialog } = props;
  const classes = useStyles(props);
  const [showTasksForBadge, setShowTasksForBadge] = useState<boolean>(false);
  const setShowTasksForBadgedClose = useCallback(() => setShowTasksForBadge(false), []);
  const [isDescWindowPopUp, setDescWindowPopUp] = useState(null);

  const description = getBadgeDescriptionFromBadgeType(type);

  const hasApplicableTasks = hasApplicableTasksForBadge(tasksForBadge);
  const applicableTasksMessage = hasApplicableTasks
    ? `${tasksForBadge.length} Incomplete Task${tasksForBadge.length > 1 ? 's' : ''}`
    : 'Not Applicable';

  return (
    <>
      <div
        className={classes.container}
        onClick={
          tasksForBadge?.length
            ? () => {
                setShowTasksForBadge(true);
              }
            : () => {
                setDescWindowPopUp(true);
              }
        }
      >
        <div className={classes.badge}>
          <ProtectionBadge type={type} enabled={enabled} />
        </div>

        <div className={classes.description}>{description}</div>
        {tasksForBadge.length > 0 && (
          <div className={classes.taskStatus}>
            {enabled ? (
              <span className={classes.completed}>Completed</span>
            ) : (
              <span className={classes.notCompleted}>{applicableTasksMessage}</span>
            )}
          </div>
        )}
      </div>
      {tasksForBadge && tasksForBadge.length > 0 && (
        <Dialog open={showTasksForBadge} onClose={setShowTasksForBadgedClose} fullWidth maxWidth="md">
          <DialogContent style={{ padding: 0 }}>
            {showTasksForBadge && tasksDialog && tasksDialog(type, enabled, tasksForBadge, setShowTasksForBadgedClose)}
          </DialogContent>
        </Dialog>
      )}
      <CardDescWindow
        enabled={enabled}
        type={type}
        open={Boolean(isDescWindowPopUp)}
        onClose={() => {
          setDescWindowPopUp(false);
        }}
      />
    </>
  );
}

export default memo<Props>(ProtectionBadgeCard);
