// @flow
import React, { memo } from 'react';
import { Button } from '@material-ui/core';

type Props = {
  disabled: boolean,
  onClick: () => void,
};

const AssetTagsEditDialogAddTag = ({ disabled, onClick }: Props) => {
  return (
    <Button
      color="default"
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      Add Tag
    </Button>
  );
};

export default memo<Props>(AssetTagsEditDialogAddTag);
