//@flow
import React from 'react';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
  +value: string,
  +onChange: (value: string) => void,
  +onSubmit: () => void,
};

/*
 * Popout filter plugin.
 * User can type keywords.
 *
 * @param value - Current state.
 * @param onChange - State change.
 * @param onSubmit - Triggered by user enter key.
 */
const PopoutFilterPluginKeywordComponent = function PopoutFilterPluginKeyword({
  value,
  onChange,
  onSubmit,
}: Props) {
  return (
    <TextField
      fullWidth
      autoFocus
      value={value}
      onChange={e => onChange(e.target.value)}
      label="Enter Keyword"
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      onKeyDown={({ keyCode }: SyntheticKeyboardEvent<>) => {
        if (keyCode === 13) {
          onSubmit();
        }
      }}
    />
  );
};

export const PopoutFilterPluginKeyword = PopoutFilterPluginKeywordComponent;
