// @flow

import type {
  AssetListQuery,
  AssetListQueryVariables,
  AssetOrderBy,
} from '@dt/graphql-support/types';
import React, { memo, useEffect, useState } from 'react';

import { AssetOrderByValues } from '@dt/graphql-support/types';
import Checkbox from '@material-ui/core/Checkbox';
import { ErrorState } from '@dt/components';
import { Card, Grid } from '@material-ui/core';
import InventoryTableCellAssetGroupName from './InventoryTableCellAssetGroupName';
import InventoryTableCellAssetName from './InventoryTableCellAssetName';
import InventoryTableCellAssetTag from './InventoryTableCellAssetTag';
import { InventoryTableCellAssetTypeIconUrl } from './InventoryTableCellAssetTypeIconUrl';
import InventoryTableCellDiscoveredVia from './AssetTableCellDiscoveredVia';
import InventoryTableCellDiscoveryDate from './AssetTableCellDiscoveryDate';
import InventoryTableCellHostedOn from './AssetTableCellHostedOn';
import InventoryTableCellPolicyViolations from './InventoryTableCellPolicyViolations';
import InventoryTableCellRelatedAppsCount from './InventoryTableCellRelatedAppsCount';
import InventoryTableCellVendor from './InventoryTableCellVendor';
import InventoryTableEmptyState from './InventoryTableEmptyState';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Button, Text } from '@dt/material-components';
import assets from '@dt/graphql-support/horizon/assets';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import { useQuery } from '@dt/apollo-link-schema-rest';

// prettier-ignore
export const columnEnum = {
  asset_group:         ('asset_group':         'asset_group'),
  asset_name:          ('asset_name':          'asset_name'),
  asset_type_icon_url: ('asset_type_icon_url': 'asset_type_icon_url'),
  discovered_date:     ('discovered_date':     'discovered_date'),
  discovered_via:      ('discovered_via':      'discovered_via'),
  hosted_on:           ('hosted_on':           'hosted_on'),
  policy_violations:   ('policy_violations':   'policy_violations'),
  asset_tag:           ('asset_tag':           'asset_tag'),
  related_apps:        ('related_apps':        'related_apps'),
  vendor:              ('vendor':              'vendor'),
};

export type ColumnEnum = $Values<typeof columnEnum>;

const columnType: $Exact<$ObjMap<typeof columnEnum, () => *>> = {
  [columnEnum.asset_group]: {
    title: 'Current Asset Group',
    justify: 'center',
    width: 150,
  },
  [columnEnum.asset_name]: {
    title: 'Asset Name',
    justify: 'left',
    width: 220,
  },
  [columnEnum.asset_type_icon_url]: {
    title: 'Type',
    justify: 'center',
    width: 40,
  },
  [columnEnum.discovered_date]: {
    title: 'Discovery Date',
    width: 170,
    justify: 'center',
    sortable: true,
    sortEnum: AssetOrderByValues.date_created_desc,
  },
  [columnEnum.discovered_via]: {
    title: 'Discovered Via',
    justify: 'left',
    width: 160,
  },
  [columnEnum.hosted_on]: {
    title: 'Hosted On',
    justify: 'left',
    width: 150,
  },
  [columnEnum.policy_violations]: {
    title: 'Policy Violations',
    width: 180,
    justify: 'left',
    sortable: true,
    sortEnum: AssetOrderByValues.policy_violations_count_desc,
  },
  [columnEnum.asset_tag]: {
    title: 'Asset Tag',
    justify: 'center',
    width: 200,
  },
  [columnEnum.related_apps]: {
    title: 'Related Apps',
    justify: 'left',
    width: 140,
  },
  [columnEnum.vendor]: {
    title: 'Vendor',
    justify: 'left',
    width: 140,
  },
};

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    backgroundColor: palette.white,
    '& td,th': {
      padding: 4,
    },
    '& td,th:first-child': {
      paddingLeft: 8,
    },
  },
  tableHeader: {
    '& th': {
      backgroundColor: palette.white,
    },
  },
});

type PropsCommon = {
  columns: Array<ColumnEnum>,
  filters?: ?AssetListQueryVariables,
  productBasePath?:
    | '/api'
    | '/web'
    | '/cloud'
    | '/mobile-secure'
    | '/supply-chain',
  emptyStateVariant: 'no-match' | 'asset-group' | 'no-asset',
  isLoading?: boolean,
  allAssetsCheckedCount?: number,
  setAllAssetsCheckedCount?: number => void,
  isCard?: boolean,
  isDisabled?: boolean,
};

type Props =
  | {
      checkboxSelection: true,
      checkedIds: Array<string>,
      onChangeSelection: (Array<string>) => void,
      ...PropsCommon,
    }
  | {
      checkboxSelection?: false,
      ...PropsCommon,
    };

const InventoryTableComponent = function InventoryTable({
  columns,
  filters,
  productBasePath,
  isLoading,
  emptyStateVariant,
  allAssetsCheckedCount,
  setAllAssetsCheckedCount,
  ...restProps
}: Props) {
  const css = useStyles();
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<?AssetOrderBy>(
    AssetOrderByValues.date_created_desc,
  );

  const hasFilters = Object.keys(filters || {}).length > 0;

  let { data, loading, fetchMore, error } = useQuery<
    AssetListQuery,
    AssetListQueryVariables,
  >(assets.list, {
    variables: {
      ...filters,
      order_by: hasFilters ? undefined : orderBy,
    },
  });

  //reset Page number on filter changes
  useEffect(() => {
    setPage(0);
  }, [filters]);

  const handleChangePage = (event, newPage) => {
    if ((data?.asset_list.assets.length || 0) / 10 / (newPage + 1) <= 1) {
      fetchMore && fetchMore();
    }
    if (restProps.checkboxSelection) {
      // uncheck all Ids
      restProps.onChangeSelection([]);
    }
    setPage(newPage);
  };

  /* Checkbox related functions start */
  const toggleCheckBoxId = (id: string) => {
    if (restProps.checkboxSelection) {
      if (restProps.checkedIds.includes(id)) {
        restProps.onChangeSelection(
          (restProps.checkedIds || []).filter(el => el !== id) || [],
        );
      } else {
        restProps.onChangeSelection([...restProps.checkedIds, id]);
      }
    }
  };

  const toggleAllAssetIdsWithinCurrentPage = () => {
    if (restProps.checkboxSelection) {
      if (restProps.checkedIds.length === 10) {
        // uncheck all Ids
        restProps.onChangeSelection([]);
        setAllAssetsCheckedCount && setAllAssetsCheckedCount(0);
      } else {
        //check all Ids
        restProps.onChangeSelection(
          data?.asset_list.assets
            .slice(page * 10, page * 10 + 10)
            .map(({ id }) => id) || [],
        );
      }
    }
  };
  /* Checkbox related functions ends */

  // Deselect ids that were removed through triaging.
  useEffect(() => {
    const assetIds = data?.asset_list.assets.map(a => a.id);
    if (!assetIds) {
      return;
    }
    if (restProps.checkboxSelection && restProps.checkedIds.length > 0) {
      const updatedCheckedIds = restProps.checkedIds.filter(id =>
        assetIds.includes(id),
      );
      if (updatedCheckedIds.length !== restProps.checkedIds.length) {
        restProps.onChangeSelection(updatedCheckedIds);
      }
    }
  }, [data, restProps]);

  if (error) {
    return <ErrorState error={error} />;
  }

  const paginationInformation = data?.asset_list.pagination_information;
  let paginationCount = paginationInformation?.total_count || 0;
  if (paginationInformation?.is_approximate_total_count_enabled) {
    if (paginationInformation?.exact_total_count) {
      paginationCount = paginationInformation.exact_total_count;
    } else {
      paginationCount = paginationInformation?.hint_for_total_count || 0;
    }
  }

  const tableContainerFragment = (
    <>
      <TableContainer>
        <Table stickyHeader size="small" className={css.table}>
          <TableHead className={css.tableHeader}>
            <TableRow>
              {restProps.checkboxSelection && (
                <TableCell width={48}>
                  {loading || isLoading ? (
                    <Skeleton animation="wave" height={42} width={42} />
                  ) : (
                    <Checkbox
                      data-testid="select-all-checkbox"
                      checked={Boolean(restProps.checkedIds.length)}
                      disabled={restProps.isDisabled}
                      indeterminate={
                        restProps.checkedIds.length > 0 &&
                        restProps.checkedIds.length < 10
                      }
                      onClick={() => toggleAllAssetIdsWithinCurrentPage()}
                    />
                  )}
                </TableCell>
              )}
              {columns.map((column, key) => {
                return (
                  <TableCell
                    key={`header-${key}`}
                    align={columnType[column].justify}
                    width={columnType[column].width}
                    sortDirection={columnType[column].sortable ? 'desc' : false}
                  >
                    {loading || isLoading ? (
                      <Skeleton animation="wave" height={45} width={'100%'} />
                    ) : columnType[column].sortable ? (
                      <TableSortLabel
                        active={
                          hasFilters
                            ? false
                            : orderBy === columnType[column].sortEnum
                        }
                        direction={'desc'}
                        onClick={() => {
                          if (columnType[column].sortable) {
                            setOrderBy(columnType[column].sortEnum);
                            setPage(0);
                          }
                        }}
                      >
                        {columnType[column].title}
                      </TableSortLabel>
                    ) : (
                      <Text
                        variant={'body'}
                        component={'p'}
                        style={{
                          margin: 0,
                        }}
                      >
                        {columnType[column].title}
                      </Text>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || isLoading
              ? [...new Array(10)].map((_, key) => (
                  <TableRow key={`loading-${key}`}>
                    {restProps.checkboxSelection && (
                      <TableCell>
                        <Skeleton animation="wave" height={47} width={32} />
                      </TableCell>
                    )}
                    {columns.map((column, key) => (
                      <TableCell
                        key={`loading-cell-${key}`}
                        align={'center'}
                        width={columnType[column].width}
                      >
                        <Skeleton animation="wave" height={47} width={'100%'} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : data?.asset_list.assets
                  .slice(page * 10, page * 10 + 10)
                  .map(asset => (
                    <TableRow
                      key={asset.id}
                      onClick={() => {
                        restProps.checkboxSelection &&
                          toggleCheckBoxId(asset.id);
                        setAllAssetsCheckedCount && setAllAssetsCheckedCount(0);
                      }}
                    >
                      {restProps.checkboxSelection && (
                        <TableCell>
                          <Checkbox
                            data-testid={`${asset.name}-checkbox`}
                            checked={restProps.checkedIds.includes(asset.id)}
                            disabled={restProps.isDisabled}
                          />
                        </TableCell>
                      )}
                      {columns.map(column =>
                        column === columnEnum.asset_type_icon_url ? (
                          <InventoryTableCellAssetTypeIconUrl
                            asset={asset}
                            key={`cell-iconurl-${asset.id}`}
                          />
                        ) : column === columnEnum.asset_name ? (
                          <InventoryTableCellAssetName
                            asset={asset}
                            productBasePath={productBasePath}
                            key={`cell-name-${asset.id}`}
                          />
                        ) : column === columnEnum.policy_violations ? (
                          <InventoryTableCellPolicyViolations
                            asset={asset}
                            key={`cell-pv-${asset.id}`}
                          />
                        ) : column === columnEnum.hosted_on ? (
                          <InventoryTableCellHostedOn
                            iconUrl={asset.hosted_on_icon_url}
                            name={asset.hosted_on_name}
                            key={`cell-hosted-${asset.id}`}
                          />
                        ) : column === columnEnum.discovered_via ? (
                          <InventoryTableCellDiscoveredVia
                            key={`cell-discovered-${asset.id}`}
                            name={asset.discovered_via_name}
                            iconUrl={asset.discovered_via_icon_url}
                            maxWidth={columnType[column].width}
                          />
                        ) : column === columnEnum.discovered_date ? (
                          <InventoryTableCellDiscoveryDate
                            date_created={asset.date_created}
                            key={`cell-date-${asset.id}`}
                          />
                        ) : column === columnEnum.asset_group ? (
                          <InventoryTableCellAssetGroupName
                            asset_group={asset.belongs_to_asset_group}
                            key={`cell-asset-group-${asset.id}`}
                          />
                        ) : column === columnEnum.asset_tag ? (
                          <InventoryTableCellAssetTag
                            assetId={asset.id}
                            assetName={asset.name}
                            assetTags={asset.tags}
                            key={`cell-asset-tag-${asset.id}`}
                          />
                        ) : column === columnEnum.related_apps ? (
                          <InventoryTableCellRelatedAppsCount
                            included_supply_chain_secure_details={
                              asset?.included_supply_chain_secure_details
                            }
                            key={`cell-related-apps-${asset.id}`}
                          />
                        ) : column === columnEnum.vendor ? (
                          <InventoryTableCellVendor
                            vendor={
                              asset.included_supply_chain_secure_details?.vendor
                            }
                            key={`cell-vendor-${asset.id}`}
                          />
                        ) : null,
                      )}
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading || isLoading ? (
        <Grid container spacing={1} justify={'flex-end'}>
          <Grid item style={{ marginRight: 15 }}>
            <Skeleton animation="wave" height={45} width={120} />
          </Grid>
        </Grid>
      ) : data?.asset_list.pagination_information.total_count === 0 ? (
        <InventoryTableEmptyState variant={emptyStateVariant} />
      ) : (
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => {
            const isInexact =
              paginationInformation?.is_approximate_total_count_enabled &&
              !paginationInformation?.exact_total_count &&
              paginationInformation.hint_for_total_count;

            return `${from}-${to} of ${count}${isInexact ? '+' : ''}`;
          }}
          component="div"
          count={paginationCount}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
          page={page}
          onChangePage={handleChangePage}
          nextIconButtonProps={
            loading || restProps.isDisabled ? { disabled: true } : null
          }
        />
      )}
    </>
  );

  return (
    <>
      {restProps.checkboxSelection &&
        (restProps?.checkedIds.length === 10 ? (
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justify="center"
            spacing={0}
            style={{
              background: palette.gray50,
              paddingLeft: 12,
              paddingTop: 8,
              paddingBottom: 8,
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <Grid item xs={6}>
              <Text
                variant="body"
                style={{ margin: 0, display: 'flex', whiteSpace: 'pre' }}
              >
                {'All '}
                <Text variant="titleXS" style={{ margin: 0 }}>
                  {allAssetsCheckedCount
                    ? data?.asset_list.pagination_information?.total_count
                    : restProps?.checkedIds.length}
                </Text>
                {' matching assets '}
                {Boolean(!allAssetsCheckedCount) && 'on this page '}
                {'are selected'}
              </Text>
            </Grid>
            <Grid item xs={6}>
              {allAssetsCheckedCount ? (
                <Button
                  onClick={() => {
                    toggleAllAssetIdsWithinCurrentPage();
                  }}
                >
                  {'CLEAR SELECTION'}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setAllAssetsCheckedCount &&
                      setAllAssetsCheckedCount(
                        allAssetsCheckedCount ===
                          data?.asset_list.pagination_information?.total_count
                          ? 0
                          : Number(
                              data?.asset_list.pagination_information
                                ?.total_count,
                            ),
                      );
                  }}
                >
                  {'SELECT ALL '}
                  {data?.asset_list.pagination_information?.total_count}
                  {' ASSETS'}
                </Button>
              )}
            </Grid>
          </Grid>
        ) : null)}
      {restProps.isCard ? (
        <Card>{tableContainerFragment}</Card>
      ) : (
        tableContainerFragment
      )}
    </>
  );
};

export default memo<Props>(InventoryTableComponent);
