// @flow
import React from 'react';

// Logos and icons
import OperationsIcon from '@material-ui/icons/Build';

import Tag from './Tag';

type Props = {|
  +count: number,
  +size?: 'large' | 'small',
|};

function TagApiOperationsCount({ count, size }: Props) {
  const postfix = size !== 'small' ? 'Operation' : 'API operation';
  const text = `${count} ${postfix}${count !== 1 ? 's' : ''}`;

  return (
    <Tag
      icon={<OperationsIcon style={{ height: 14, width: 14 }} />}
      label={text}
    />
  );
}

export default React.memo<Props>(TagApiOperationsCount);
