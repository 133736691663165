//@flow
import React from 'react';
import {
  MenuItem,
  FormControl,
  Grid,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { UTCToLocalTime, roundHour, hoursOfDay } from '@dt/date';

type Props = {
  time: string,
  onChange: (newTime: string) => void,
  showLocalTime?: boolean,
  variant?: 'standard' | 'outlined' | 'filled',
  classes?: { ... },
};

function TimeInput({
  time,
  onChange,
  showLocalTime = false,
  variant,
  classes,
}: Props) {
  return (
    <Grid container>
      <FormControl component="fieldset" aria-label="time-choice">
        <Select
          value={roundHour(time)}
          onChange={e => onChange(e.target.value)}
          displayEmpty
          MenuProps={{ disablePortal: true }}
          variant={variant}
          classes={classes}
        >
          <MenuItem value=""> --:-- </MenuItem>
          {hoursOfDay.map(([h, offset]) => (
            <MenuItem value={h + offset} key={h + offset}>
              {h} UTC
            </MenuItem>
          ))}
        </Select>
        {showLocalTime && (
          <FormHelperText>
            {time.length ? `Local time: ${UTCToLocalTime(time)}` : ' '}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

export default React.memo<Props>(TimeInput);
