//@flow
import React, { memo, useMemo, useState } from 'react';
import { Grid, Card, CardContent, InputBase, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import debounce from 'lodash/fp/debounce';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  CardContent: props => ({
    padding: props.size == 'small' ? `0px 8px;` : 8,
    '&:last-child': {
      paddingBottom: props.size == 'small' ? `0px 8px;` : 8,
    },
  }),
});

type Props = {
  +id?: string,
  +ariaLabel?: string,
  +autoFocus?: boolean,
  +fullWidth?: boolean,
  value: ?string,
  placeholder?: ?string,
  onChange: string => void,
  size?: 'small' | 'medium',
};

export default memo<Props>(function KeyWordSearchInput({
  id,
  ariaLabel,
  autoFocus,
  fullWidth,
  value,
  onChange,
  placeholder,
  size,
}: Props) {
  const classes = useStyles({ size });

  const [inputValue, setInputValue] = useState<?string>(value);

  const handleChange = useMemo(() => debounce(500)((value: string) => onChange(value)), [onChange]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent classes={{ root: classes.CardContent }}>
            <InputBase
              id={id}
              aria-label={ariaLabel}
              autoFocus={autoFocus}
              inputProps={{ 'data-testid': '_keyword-search' }}
              fullWidth={typeof fullWidth !== 'boolean' ? true : fullWidth}
              value={inputValue || ''}
              onChange={e => {
                setInputValue(e.target.value);
                handleChange(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder={placeholder || 'Keyword Search'}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
