// @flow

import React from 'react';

import {
  Select,
  MenuItem,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';

type Props<T> = {
  options: $ReadOnlyArray<{| +label: string, +value: T |}>,
  values: $ReadOnlyArray<T>,
  placeholder: string,
  label: string,
  onChange: ($ReadOnlyArray<T | 'ANY'>) => mixed,
  ...
};

const FilterMultiSelectField = <T: string | number>(props: Props<T>) => {
  return (
    <FormControl>
      <InputLabel htmlFor={props.label.replace(/ /, '_')} shrink={true}>
        {props.label}
      </InputLabel>
      <Select
        autoWidth
        id={props.label.replace(/ /, '_')}
        style={{ marginRight: '10px', minWidth: 200 }}
        multiple
        value={props.values}
        input={<Input id={props.label.replace(/ /, '_')} />}
        displayEmpty
        renderValue={selected => {
          if (!Array.isArray(selected)) {
            throw new Error('expected this select to only have array values');
          }
          if (selected.length === 0) {
            return <em>{props.placeholder}</em>;
          }

          return selected
            .map(value => {
              const option = props.options.find(l => l.value === value);
              if (!option) return value;
              return option.label;
            })
            .join(', ');
        }}
        onChange={e => {
          // $FlowFixMe: material-ui guarantees an array on e.target.value https://material-ui.com/components/selects/#multiple-select
          const values: string[] = e.target.value;

          // if user is selecting "ANY"
          if (values.includes('ANY')) {
            // and ANY was selected already
            if (props.values.includes('ANY')) {
              // then remove it
              props.onChange(values.filter(v => v !== 'ANY'));
              return;
            }
            // otherwise, replace everything with just ANY;
            props.onChange(['ANY']);
            return;
          }

          props.onChange(values);
        }}
      >
        {props.options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterMultiSelectField;
