//@flow
import React, { memo } from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import Box from '@material-ui/core/Box';
import Text from './Text';
import { palette } from '@dt/theme';

type Props =
  | {|
      +variant: 'center',
      +gutterBottom?: boolean,
    |}
  | {|
      +variant: 'inline',
      +gutterBottom?: boolean,
      +onAddNewClicked: () => mixed,
    |};

/*
 * An empty state for when there are no policy violation comments.
 *
 * @param variant - Center or inline component
 * @param gutterBottom - Whether or not to put a margin bottom on the end of the component
 * @param onAddNewClicked - Triggered when the user wants to add a new comment
 */
const PolicyViolationsCommentsListEmptyComponent = (props: Props) => {
  if (props.variant === 'center') {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor="unset"
        p={0}
        borderRadius={0}
        marginBottom={!props.gutterBottom ? 0 : 40}
        style={{
          textAlign: 'center',
          minHeight: 210,
        }}
      >
        <ChatIcon style={{ fontSize: '4rem', color: palette.gray20 }} />
        <Text
          variant="body"
          style={{
            marginTop: '0px',
            marginLeft: '0px',
            color: palette.gray35,
            maxWidth: '400px',
          }}
        >
          You can use this section to send messages to Data Theorem&apos;s
          support team or make notes
        </Text>
      </Box>
    );
  } else if (props.variant === 'inline') {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        bgcolor={palette.gray50}
        borderRadius={4}
        style={{
          textAlign: 'left',
          minHeight: 0,
          padding: 24,
          marginBottom: !props.gutterBottom ? 0 : 40,
        }}
      >
        <ChatIcon style={{ fontSize: '2.5rem', color: palette.gray20 }} />
        <Box display="flex" flexDirection="column" marginLeft={3}>
          <Text
            variant="titleXS"
            color={palette.gray30}
            style={{ margin: 0, padding: 0 }}
          >
            Zero Technical Questions/Notes
          </Text>
          <Text
            variant="body"
            color={palette.gray35}
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            Click{' '}
            <Text variant="link" style={{ cursor: 'pointer' }}>
              <span onClick={props.onAddNewClicked}>here</span>
            </Text>{' '}
            to send technical questions to the AppSec Research team
          </Text>
        </Box>
      </Box>
    );
  }

  // Should never happen, props are explicitly required.
  // Any malformed props will be caught by the type checker.
  throw new Error('No "PolicyViolationsCommentsListEmpty" component found.');
};

export const PolicyViolationsCommentsListEmpty = memo<Props>(
  PolicyViolationsCommentsListEmptyComponent,
);
