//@flow
import React from 'react';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Text from './Text';
import GetAppIcon from '@material-ui/icons/GetApp';
import { generateTextFile } from '@dt/functions';
import { palette } from '@dt/theme';

const withStyles = makeStyles(() => ({
  container: {
    overflowX: 'auto',
  },
  containerContent: {
    padding: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

type Props = {
  displayText: ?string,
};
export default function DisplayAndDownloadCodeBlock({ displayText }: Props) {
  const classes = withStyles();

  return (
    <Card>
      <CardContent>
        <Grid
          container
          className={classes.container}
          direction="row"
          alignItems="flex-end"
        >
          <Grid item xs={10}>
            <Text variant="code" className={classes.containerContent}>
              {displayText
                ? displayText
                : 'Currently unavailable. Please check back later!'}
            </Text>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            {displayText ? (
              <Button
                variant="contained"
                color="default"
                aria-label="download full schema button"
                size="small"
                onClick={() => generateTextFile(displayText, 'full-schema.txt')}
                startIcon={
                  <GetAppIcon style={{ color: palette.gray20, fontSize: 16 }} />
                }
              >
                <Text variant="button">Full Schema</Text>
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
