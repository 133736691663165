// @flow
import React from 'react';
import { TextField } from '@material-ui/core';

const wStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'baseline',
};

type Props = {
  value: string,
  onChange: string => void,
  hintText?: string,
  keywordText?: string,
  ...
};

const defaultHintTex =
  'Enter a keyword to narrow results (default: all findings)';

const defaultKeywordText = 'Keyword:';

const SearchBox = ({ value, hintText, onChange, keywordText }: Props) => {
  const handleChange = e => onChange(e.target.value);
  return (
    <div style={wStyle}>
      <div style={{ display: 'inline-block', marginRight: '10px' }}>
        {keywordText || defaultKeywordText}
      </div>
      <TextField
        placeholder={hintText || defaultHintTex}
        onChange={handleChange}
        defaultValue={value}
        fullWidth
      />
    </div>
  );
};

export default SearchBox;
