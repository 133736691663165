import React from 'react';
import Box from '@material-ui/core/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { SvgIcon, Tooltip } from '@material-ui/core';
var currentRouteStyle = function (isCurrent, disabled) { return ({
    borderRadius: 12,
    backgroundColor: disabled ? '#ccc' : isCurrent ? palette.white : 'initial',
    color: disabled ? '#666' : isCurrent ? palette.brand30 : palette.gray30,
    fill: isCurrent ? palette.brand30 : palette.gray30,
    padding: '8px 12px',
    margin: 0,
    height: 38,
}); };
var NavigationItemContent = function (_a) {
    var Icon = _a.Icon, isCurrent = _a.isCurrent, label = _a.label, isSvg = _a.isSvg, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.tooltip, tooltip = _c === void 0 ? false : _c;
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: tooltip ? label : '' }, !Icon ? (React.createElement(Text, { variant: "titleS", style: currentRouteStyle(isCurrent, disabled) }, label)) : (React.createElement(Box, null, isSvg ? (React.createElement("div", { style: currentRouteStyle(isCurrent, disabled) },
            React.createElement(SvgIcon, null, Icon))) : (React.createElement(Box, { style: currentRouteStyle(isCurrent, disabled) },
            React.createElement(Icon, null))))))));
};
export default NavigationItemContent;
