// @flow
import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
  type Node,
} from 'react';
import ClipboardIcon from './icons/ClipboardIcon';
import copy from './copyToClipboard.util';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';

type Props = {|
  label?: Node,
  value: string,
  size?: number | string,
  color?: string,
  showClipboardIcon?: boolean,
|};

const useStyles = makeStyles({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
  },

  floating: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,

    transform: 'translate(0, -40px)',
    animationName: 'FadeInUp',
    animationDuration: '2s',
    opacity: 0,
    fontSize: 12,
  },

  '@keyframes FadeInUp': {
    '0%': {
      transform: 'translate(0, 0px)',
      opacity: 1,
    },
    '30%': {
      transform: 'translate(0, -20px)',
      opacity: 1,
    },
    '100%': {
      transform: 'translate(0, -40px)',
      opacity: 0,
    },
  },
});

function CopyToClipboard(props) {
  const { showClipboardIcon, label, size = 14, value, color } = props;
  const classes = useStyles();
  const [copied, setCopied] = useState<boolean>(false);
  const timeout = useRef<?TimeoutID>();
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
    },
    [],
  );

  const handleClick = useCallback(() => {
    // Slow down to avoid flickering the animation
    if (copied) return;

    setCopied(true);
    copy(value);

    timeout.current = setTimeout(() => setCopied(false), 2500);
  }, [copied, value]);

  return (
    <span onClick={handleClick} className={classes.container}>
      {copied && <span className={classes.floating}>Copied!</span>}
      {label ? label : 'Copy'}
      {showClipboardIcon && (
        <ClipboardIcon
          htmlColor={color || palette.gray30}
          style={{
            width: size,
            height: size,
            marginLeft: 5,
          }}
        />
      )}
    </span>
  );
}

export default memo<Props>(CopyToClipboard);
