// @flow
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CloudUpload from '@material-ui/icons/CloudUpload';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Attachment from '@material-ui/icons/Attachment';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import Button from '@material-ui/core/Button';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import {
  uploadPoFileAction,
  closeUploadPoComponentAction,
} from '../redux/actions';
import {
  container,
  modal,
  paper,
  root,
  actions,
  link,
  button,
  icon,
  input,
  dropzone,
  p,
} from '../style';

let useStyles = makeStyles({
  container,
  modal,
  paper,
  root,
  actions,
  link,
  button,
  icon,
  input,
  dropzone,
  p,
});

type Props = {||};

// genrate a text file from po number
const generatePoFileFromPoNumber = (poNumber: string): File => {
  const generatedFile = new Blob([`PO NUMBER: ${poNumber}`], {
    type: 'plain/text',
  });
  return new File([generatedFile], 'po_number.txt', {
    type: 'plain/text',
    lastModified: Date.now(),
  });
};

const UploadPo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [poNumberValue, setPoNumberValue] = useState('');
  const [submitionStatus, submitAction] = useState({
    submited: false,
    poFile: undefined,
    error: false,
  });

  const handlePoNumberChange = event => {
    setPoNumberValue(event.target.value);
  };

  const [isDragEnter, onDragEnterHandler] = useState(false);
  const [isDragRejected, onDragRejectedHandler] = useState(false);

  const onDragOver = useCallback(() => {
    onDragEnterHandler(true);
  }, []);

  const onDragLeave = useCallback(() => {
    onDragEnterHandler(false);
  }, []);

  const onDrop = useCallback(() => {
    onDragEnterHandler(false);
  }, []);

  const onDropRejected = useCallback(() => {
    onDragRejectedHandler(true);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
  } = useDropzone({
    onDragOver,
    onDragLeave,
    onDropRejected,
    onDrop,
    accept:
      '.doc, .docx, .txt, .text, .xls, .xlsx, plain/txt, plain/text, text/html, application/pdf, application/zip',
    disabled: poNumberValue.length ? true : false,
  });

  const style = useMemo(
    () => ({
      ...dropzone,
      borderColor:
        isDragActive || isFocused
          ? '#2196f3'
          : submitionStatus.submited && submitionStatus.error
          ? 'red'
          : null,
      backgroundColor:
        submitionStatus.submited && submitionStatus.error ? '#ffecea' : null,
      color:
        submitionStatus.submited && submitionStatus.error ? '#8c8c8c' : null,
      opacity: poNumberValue.length ? '0.2' : {},
    }),
    [isDragActive, isFocused, submitionStatus, poNumberValue],
  );

  const handleCloseHandler = () => {
    dispatch(closeUploadPoComponentAction());
  };

  const getPoFile = (): File | void => {
    let poFile =
      acceptedFiles.length > 0
        ? acceptedFiles[acceptedFiles.length - 1]
        : undefined;
    if (!poFile && poNumberValue.length) {
      poFile = generatePoFileFromPoNumber(poNumberValue);
    }
    // IDK why! but we need to build a new File Object from
    // dragged file to get response from google storage api
    return poFile && new File([poFile], poFile.name);
  };

  useEffect(() => {
    if (submitionStatus.submited && !submitionStatus.poFile) {
      submitAction({
        ...submitionStatus,
        error: true,
      });
    }

    if (
      submitionStatus.submited &&
      submitionStatus.poFile &&
      !submitionStatus.error
    ) {
      const name = submitionStatus.poFile.name;
      const desc = '*** renewal reminder ***';
      dispatch(uploadPoFileAction(submitionStatus.poFile, name, desc));
      dispatch(closeUploadPoComponentAction());
    }
  }, [submitionStatus, dispatch]);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={true}
        onClose={handleCloseHandler}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.root}>
          <Grid container className={classes.paper} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h5">
                Upload PO
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <section className="container">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  {!isDragEnter && !(acceptedFiles.length > 0) && (
                    <p className={classes.p}>
                      <span className={classes.icon}>
                        {isDragRejected ? <BrokenImageIcon /> : <NoteAdd />}
                      </span>
                      {isDragRejected && (
                        <span>Your file is not an accepted PO file.</span>
                      )}
                      <span>
                        Drag and drop or click to select your PO file or
                      </span>
                      <span>input a PO number to continue</span>
                    </p>
                  )}
                  {isDragEnter && (
                    <p className={classes.p}>
                      <span className={classes.icon}>
                        <CloudUpload />
                      </span>
                      <span>Drop your PO file to upload</span>
                    </p>
                  )}
                  {acceptedFiles.length > 0 && !isDragEnter && (
                    <p className={classes.p}>
                      <span className={classes.icon}>
                        <Attachment />
                      </span>
                      <span>Your PO File is ready to upload.</span>
                    </p>
                  )}
                </div>
              </section>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={6}>
                  <TextField
                    id="outlined-full-width"
                    label="PO Number"
                    style={{ margin: 2 }}
                    value={poNumberValue}
                    onChange={handlePoNumberChange}
                    placeholder="PO Number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button
                    color="primary"
                    aria-label={'SUBMIT'}
                    className={classes.button}
                    variant="contained"
                    onClick={() =>
                      submitAction({
                        ...submitionStatus,
                        submited: true,
                        poFile: getPoFile(),
                      })
                    }
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo<Props>(UploadPo);
