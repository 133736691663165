// @flow
import React, { type Node } from 'react';
import { type Properties } from 'csstype';

import { Container } from '@material-ui/core';

type Props = {
  children: Node,
  fullWidth?: boolean,
  classes?: { +[string]: string, ... },
  style?: Properties<>,
};

const PageLayoutComponent = function PageLayout({
  children,
  fullWidth,
  classes,
  style = {},
}: Props) {
  return (
    <Container
      maxWidth={fullWidth ? false : 'lg'}
      disableGutters={fullWidth}
      classes={classes}
      style={{
        paddingTop: fullWidth ? 0 : 8,
        paddingBottom: 16,
        ...style,
      }}
    >
      {children}
    </Container>
  );
};

export default PageLayoutComponent;
