// @flow
import React, { memo } from 'react';
import { Button, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';

type Props = {
  confirm: () => void,
  cancel: () => void,
  disabled: boolean,
};

const AssetTagsEditDialogConfirmRemove = ({ confirm, disabled, cancel }) => {
  return (
    <Grid container justify={'flex-start'}>
      <Box>
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={confirm}
        >
          Save
        </Button>
      </Box>
      <Box ml={1}>
        <Button color="default" variant="contained" onClick={cancel}>
          Cancel
        </Button>
      </Box>
    </Grid>
  );
};

export default memo<Props>(AssetTagsEditDialogConfirmRemove);
