//@flow
import React, { memo, forwardRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { palette } from '@dt/theme';
import { fromNowTime } from '@dt/date';
import Tag from './Tag';
import type { Node } from 'react';

const useStyles = makeStyles({
  comment: {
    minWidth: 350,
  },
  text: ({ isFlat, matchesLg }) => ({
    marginLeft: matchesLg ? (!isFlat ? 45 : 50) : !isFlat ? 0 : 50,
    marginTop: matchesLg ? (!isFlat ? -24 : -30) : 0,
    backgroundColor: !isFlat ? palette.white : 'unset',
    borderRadius: 5,
    padding: !isFlat ? 10 : 'unset',
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    lineHeight: '1.65',
    color: palette.gray20,
  }),
  metaContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginBottom: 10,
  },
  author: {
    display: 'flex',
    alignItems: 'center',
    color: palette.gray30,
  },
  date: {
    color: palette.gray35,
    lineHeight: '0.75',
  },
  internalNote: {
    backgroundColor: palette.orange50,
  },
});

type Props = {|
  +author?: ?string,
  +date?: ?string,
  +is_internal_comment: boolean,
  +isFlat?: boolean,
  +children: ?Node,
  +hideTag?: ?boolean,
|};

/*
 * Show a policy violation comment to the user.
 *
 * @param author - Author of the comment provided by the backend
 * @param date - Date posted of the comment provided by the backend
 * @param is_internal_comment - Indicates if the comment was marked as an internal comment provided by the backend
 * @param isFlat - Whether or not to render the component flattened or not
 * @param children - Content of the comment
 */
const PolicyViolationsCommentsItemComponent = function PolicyViolationsCommentsItem(
  { author, date, is_internal_comment, isFlat, children, hideTag },
  ref,
) {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles({ isFlat, matchesLg });

  const authorParts = author?.split(' ');

  return (
    <Box ref={ref} display="flex" flexDirection="column" py={1} px={2} className={classes.comment}>
      <div>
        <div className={classes.metaContainer}>
          <Avatar
            style={{
              marginRight: 10,
              textTransform: 'uppercase',
              backgroundColor: author === 'Data Theorem' ? palette.brand40 : palette.gray30,
              color: author === 'Data Theorem' ? palette.brand50 : palette.gray45,
            }}
          >
            {authorParts
              ? `${authorParts[0].charAt(0)}${
                  authorParts.length > 1 ? authorParts[authorParts.length - 1].charAt(0) : ''
                }`
              : '?'}
          </Avatar>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: matchesLg ? 'row' : 'column',
              justifyContent: 'space-between',
              alignItems: matchesLg ? 'center' : 'flex-start',
              marginTop: matchesLg ? -24 : 0,
            }}
          >
            {!hideTag && author && (
              <div className={classes.author}>
                <Typography variant="body1" style={{ fontSize: 14, fontWeight: 700 }}>
                  {author}
                </Typography>
                {is_internal_comment && (
                  <Tag
                    backgroundColor={palette.orange50}
                    label="Internal Note"
                    tooltipText="Comment is an Internal Note"
                  />
                )}
              </div>
            )}

            <div className={classes.date}>{date && fromNowTime(date)}</div>
          </div>
        </div>
      </div>

      <div className={classes.text}>{children}</div>
    </Box>
  );
};

export const PolicyViolationsCommentsItem = memo<Props>(forwardRef<Props, _>(PolicyViolationsCommentsItemComponent));
