//@flow
import React, { memo } from 'react';
import isAfter from 'date-fns/is_after';
import { Grid, Card, CardContent } from '@material-ui/core';
import { PolicyViolationsCommentsSectionHeader } from './PolicyViolationsCommentsSectionHeader';
import { PolicyViolationsCommentsSectionBody } from './PolicyViolationsCommentsSectionBody';
import type { Node } from 'react';

type Comment = {
  +id: ?string,
  +author_email: ?string,
  +date: ?string,
  +is_internal_comment: boolean,
  +text: ?string,
  ...
};

const sortComments = function sortComments(a: Comment, b: Comment) {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  if (isAfter(a.date, b.date)) {
    return 1;
  } else {
    return -1;
  }
};

type Props = {
  +variant?: 'card' | 'section',
  +comments: ?$ReadOnlyArray<Comment>,
  +isCommentsWorkflowOpen: boolean,
  +onOpenWorkflow: () => void,
  isV2?: ?boolean,
  hideTag?: ?boolean,
};

// Render content as:
// - card
// - section
const Content = ({ variant, children }: { variant?: 'card' | 'section', children: Node }) => {
  if (variant === 'card') {
    return (
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    );
  }
  return children;
};

/*
 * Policy violation "section" used to render the latest comment and provide the user the ability to
 * open the policy violation comments workflow.
 *
 * @param variant - Render content variant.
 * @param comments - Comments of a policy violation, used to show how many comments exist
 * @param isCommentsWorkflowOpen - Whether or not the comments column is open or not
 * @param onOpenWorkflow - Triggered event when the user clicks "Appsec Advice/Internal Notes"
 */
const PolicyViolationsCommentsSectionComponent = function PolicyViolationsCommentsSection({
  variant,
  comments,
  isCommentsWorkflowOpen,
  onOpenWorkflow,
  isV2,
  hideTag = false,
}: Props) {
  const sortedComments = comments ? comments.slice().sort(sortComments) : [];
  const latestComment: void | Comment = sortedComments[sortedComments.length - 1];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PolicyViolationsCommentsSectionHeader
          sortedComments={sortedComments}
          onOpenWorkflow={onOpenWorkflow}
          isV2={isV2}
        />
      </Grid>

      <Grid item xs={12}>
        <Content variant={variant}>
          <PolicyViolationsCommentsSectionBody
            hideTag={hideTag}
            sortedComments={sortedComments}
            latestComment={latestComment}
            isCommentsWorkflowOpen={isCommentsWorkflowOpen}
            onOpenWorkflow={onOpenWorkflow}
            isV2={isV2}
          />
        </Content>
      </Grid>
    </Grid>
  );
};

export const PolicyViolationsCommentsSection = memo<Props>(PolicyViolationsCommentsSectionComponent);
