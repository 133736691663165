//@flow
import React from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import CheckMarkIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { Text } from '@dt/material-components';
import {
  getHoverDescriptionFromBadgeType,
  getHoverFeatureListFromBadgeType,
  getBadgeDescriptionFromBadgeType,
} from './util';
import type { BadgeTypeEnum } from './BadgeTypeEnum';

const useStyles = makeStyles({
  list: {
    paddingLeft: 24,
    paddingTop: 4,
    margin: 0,
    listStyleType: 'disc',
    '& .MuiListItem-root': {
      display: 'list-item',
      padding: 0,
    },
    '& .MuiListItem-root::maker': {
      color: 'red',
    },
  },
});

type Props = {
  type: BadgeTypeEnum,
  enabled: boolean,
  open: boolean,
  onClose: () => void,
};

const CardDescWindow = ({ type, enabled, open, onClose }: Props) => {
  const classes = useStyles();

  const title = getBadgeDescriptionFromBadgeType(type);
  const description = getHoverDescriptionFromBadgeType(type);
  const list = getHoverFeatureListFromBadgeType(type);

  return (
    <Dialog scroll="paper" open={open} onClose={onClose} maxWidth={'md'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box textAlign={'start'}>
          <Box display={'flex'} justifyItems={'center'}>
            {enabled ? (
              <CheckMarkIcon style={{ color: palette.green, fontSize: 'large' }} />
            ) : (
              <InfoIcon style={{ color: palette.gray, fontSize: 'large' }} />
            )}
            <Box ml={1} textAlign={'start'}>
              <Text variant={'body'} component={'div'}>
                {description}
              </Text>
            </Box>
          </Box>
          <Box ml={1} mt={2} textAlign={'start'}>
            {/* @ts-ignore */}
            <Text variant={'body'} component={'div'}>
              The following proactive security issue will implement this feature:
            </Text>
          </Box>
          <List dense className={classes.list}>
            {list?.map((item: string, idx: number) => (
              <ListItem key={idx}>
                <Text variant={'body'} component={'div'}>
                  {item}
                </Text>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CardDescWindow;
