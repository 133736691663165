//@flow
import React, { memo, type Node } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  PreviewInline: { filter: 'blur(5px)' },
});

type Props = {|
  children: Node,
|};

export default memo<Props>(function PreviewInline(props) {
  const { children } = props;
  const classes = useStyles();

  return <span className={classes.PreviewInline}>{children}</span>;
});
