//@flow
import React, { memo } from 'react';
import DownArrow from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import { Fab } from '@material-ui/core';

const useStyles = makeStyles({
  ViewMoreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 1,
    padding: 5,
    backgroundColor: palette.accent,
  },

  Fab: {
    marginLeft: '10px !important',
    color: palette.white,
  },
});

type Props = {|
  onRequestViewMore: () => mixed,
  label: string,
  count: number,
|};

export default memo<Props>(function ViewMoreButton(props) {
  const { onRequestViewMore, label, count } = props;
  const classes = useStyles();

  return (
    <div className={classes.ViewMoreButton} onClick={onRequestViewMore}>
      <span>
        View {count} more {label}
      </span>
      <Fab size="small" color="primary" className={classes.Fab}>
        <DownArrow />
      </Fab>
    </div>
  );
});
