// @flow
import React, { memo } from 'react';
import CodeIcon from '@material-ui/icons/Code';

import Tag from './Tag';

type Props = {
  value: string,
};

function TagRegion({ value }: Props) {
  return (
    <Tag
      icon={<CodeIcon fontSize="small" />}
      label={`Runtime: ${value}`.toUpperCase()}
    />
  );
}

export default memo<Props>(TagRegion);
