//@flow
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CompliancePolicyDetails from './CompliancePolicyDetails';
import Image from './../Image';

import questionMarkImage from './images/question-mark.svg';

const useStyles = makeStyles({
  textLogo: {
    borderStyle: 'solid',
    border: '2px solid #000000',
    borderRadius: 50,
    padding: '5px',
  },
});

type Props = {|
  policy: string,
  size: 'small' | 'large',
|};

function CompliancePolicyLogo(props: Props) {
  const { policy, size } = props;
  const classes = useStyles();

  if (CompliancePolicyDetails[policy]) {
    return (
      <Image
        src={
          size === 'large'
            ? CompliancePolicyDetails[policy].src
            : CompliancePolicyDetails[policy].srcSmall
            ? CompliancePolicyDetails[policy].srcSmall
            : CompliancePolicyDetails[policy].src
        }
        style={{
          width:
            size === 'small'
              ? CompliancePolicyDetails[policy].smallWidth
              : CompliancePolicyDetails[policy].largeWidth,
          height:
            size === 'small'
              ? CompliancePolicyDetails[policy].smallHeight
              : CompliancePolicyDetails[policy].largeHeight,
        }}
        onFailSrc={questionMarkImage}
        alt={CompliancePolicyDetails[policy].label}
        title={CompliancePolicyDetails[policy].label}
      />
    );
  }

  return <span className={classes.textLogo}>{policy}</span>;
}
CompliancePolicyLogo.defaultProps = {
  size: 'small',
};
export default memo<Props>(CompliancePolicyLogo);
