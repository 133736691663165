//@flow
import React, { type Element } from 'react';
import CodeIcon from '@material-ui/icons/Code';

import PythonLogo from './svg/cloud_resource_runtime_logos/python.png';
import JavaLogo from './svg/cloud_resource_runtime_logos/java.png';
import CSharpLogo from './svg/cloud_resource_runtime_logos/csharp.png';
import NodeJsLogo from './svg/cloud_resource_runtime_logos/nodejs.png';
import Tag from './Tag';

export const SUPPORTED_RUNTIMES = {
  PYTHON: {
    keywords: ['python'],
    component: (
      <img height={14} width={14} src={PythonLogo} alt="Python icon" />
    ),
  },
  CSHARP: {
    keywords: ['csharp', 'c#'],
    component: (
      <img height={14} width={14} src={CSharpLogo} alt="CSharp icon" />
    ),
  },
  JAVA: {
    keywords: ['java'],
    component: <img height={16} width={12} src={JavaLogo} alt="Java icon" />,
  },
  NODE: {
    keywords: ['node'],
    component: (
      <img
        height={22}
        width={22}
        style={{
          left: -2,
          marginLeft: -4,
          marginRight: -8,
          position: 'relative',
        }}
        src={NodeJsLogo}
        alt="NodeJS icon"
      />
    ),
  },
};

type Props = {| runtime: string |};

const RuntimeLogo = (props: { runtime: string }): Element<*> => {
  const { runtime } = props;

  const lowerCaseRuntime = runtime.toLocaleLowerCase();
  for (let k in SUPPORTED_RUNTIMES) {
    for (let keyword of SUPPORTED_RUNTIMES[k].keywords) {
      // NOTE: This logic is flimsy but until the backend solidifies the possible values for this we get to parse it.
      if (lowerCaseRuntime.indexOf(keyword) >= 0) {
        return SUPPORTED_RUNTIMES[k].component;
      }
    }
  }

  return <CodeIcon />;
};

const TagLanguageRuntime = ({ runtime }: Props) => {
  return <Tag icon={<RuntimeLogo runtime={runtime} />} label={runtime} />;
};

export default TagLanguageRuntime;
