// @flow
import React, { memo, type Node } from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

let useStyles = makeStyles({
  cardContainer: {
    backgroundColor: palette.gray50,
    minHeight: 220,
    height: '100%',
  },
});

type Props = {
  children: Node,
};

function ToolkitCard({ children }: Props) {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid container>{children}</Grid>
      </CardContent>
    </Card>
  );
}

export default memo<Props>(ToolkitCard);
