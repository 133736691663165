//@flow
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { palette } from '@dt/theme';
import { isoStringToDate } from '@dt/date';

function ifHasStuffBesidesChildren(value) {
  return ({ indicator, date }) => (indicator || date ? value : null);
}

const useStyles = makeStyles({
  CardLine: {
    display: 'flex',
    alignItems: 'baseline',
  },

  text: {
    borderLeft: ifHasStuffBesidesChildren(`1px solid #ccc`),
    marginLeft: ifHasStuffBesidesChildren(10),
    marginRight: 5,
    whiteSpace: 'nowrap',
    flex: '0 1 auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 5,
  },

  date: {
    color: palette.faded,
    marginLeft: 5,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },

  indicator: {
    whiteSpace: 'nowrap',
  },
});

type Props = {|
  indicator: any,
  date?: string | Date,
  children: any,
|};

export default memo<Props>(function CardLine(props) {
  const { indicator, date, children } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.CardLine}>
      {indicator && <span className={classes.indicator}>{indicator}</span>}
      {date && <span className={classes.date}>{isoStringToDate(date)}</span>}
      <span className={classes.text}>{children}</span>
    </div>
  );
});
