//@flow
import React from 'react';

import Button from '@material-ui/core/Button';
import { type HostedOn, HostedOnEnum } from '@dt/horizon-api';
import HostedOnIcon from './HostedOnIcon';

const HostedOnDisplayNameDict = {
  [HostedOnEnum.AMAZON_WEB_SERVICES]: 'AWS',
  [HostedOnEnum.GOOGLE_CLOUD_PLATFORM]: 'GCP',
  [HostedOnEnum.APIGEE]: 'Apigee',
  [HostedOnEnum.AZURE]: 'Azure',
  [HostedOnEnum.MULESOFT]: 'Mulesoft',
  [HostedOnEnum.KONG]: 'Kong',
  [HostedOnEnum.DETECTION_FAILED]: 'Undetected',
  [HostedOnEnum.AXWAY]: 'Axway',
  [HostedOnEnum.ON_PREMISE]: 'On Premise',
  [HostedOnEnum.GITHUB]: 'Github',
  [HostedOnEnum.GITHUB_ENTERPRISE_SERVER]: 'Github Enterprise Server',
  [HostedOnEnum.BITBUCKET]: 'Bitbucket',
  [HostedOnEnum.CLOUDFLARE]: 'Cloudflare',
  [HostedOnEnum.UNKNOWN]: 'Unknown',
};

const getDisplayNameForHostedOn = (hosted_on: HostedOn) => {
  if (HostedOnDisplayNameDict[hosted_on]) {
    return HostedOnDisplayNameDict[hosted_on];
  } else {
    throw new Error(`Missing hosted on string for ${hosted_on}, HostedOn is likely out of date`);
  }
};

type Props = {|
  hosted_on: HostedOn,
  cloud_console_url: string,
|};

const CloudConsoleUrlButton = ({ hosted_on, cloud_console_url }: Props) => {
  const hostedOnDisplayName = getDisplayNameForHostedOn(hosted_on);

  return (
    <a href={cloud_console_url} target="_blank" rel="noopener noreferrer">
      <Button
        aria-label={`View on ${hostedOnDisplayName}`}
        variant="contained"
        startIcon={<HostedOnIcon hosted_on={hosted_on} size={18} />}
      >
        <span>View in {hostedOnDisplayName}</span>
      </Button>
    </a>
  );
};

export default React.memo<Props>(CloudConsoleUrlButton);
