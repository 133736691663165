// @flow
import React, { type Node } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

type Props = {|
  onConfirm: () => void | Promise<void>,
  onCancel: () => void,
  isLoading?: boolean,
  isOpen: boolean,
  title?: string,
  children: Node,
|};

export default function ConfirmDialog(props: Props) {
  const [isRunning, setisRunning] = React.useState(false);

  async function onConfirm() {
    setisRunning(true);
    await props.onConfirm();
    setisRunning(false);
  }

  const isLoading = isRunning || props.isLoading;

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="alert-confirm-title"
      aria-describedby="alert-confirm-description"
    >
      <DialogTitle id="alert-confirm-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-confirm-description">
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={props.onCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => void onConfirm()}
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={24} />}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
