// @flow

import { palette } from '@dt/theme';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// $FlowFixMe: Not sure what's wrong here either
export default withStyles({
  switchBase: {
    '&$checked': {
      color: palette.brand30,
    },
    '&$checked + $track': {
      backgroundColor: palette.brand40,
    },
  },
  checked: {},
  track: {},
})(Switch);
