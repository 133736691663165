// @flow
import React, { memo, type Node } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import ExtLink from './ExtLink';
import Text from './Text';
import ToolkitTitle from './ToolkitTitle';
import ToolkitDivider from './ToolkitDivider';

let useStyles = makeStyles({
  paper: {
    height: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  title_row: {
    marginBottom: '20px',
  },

  header_row: {
    marginBottom: '20px',
  },

  content_row: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    marginBottom: '20px',
  },

  content_paper: {
    height: '100%',
    padding: '12px',
    backgroundColor: palette.gray50,
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
  },

  subheader_row: {
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  details_link: {
    marginTop: 'auto',
  },
});

type Props = {
  name: string,
  header: Node,
  subheader: string,
  content: Node,
  accentColor: string,
  detailsLink?: ?string,
  actionButton?: Node,
};

function ToolkitFullCard({
  name,
  header,
  subheader,
  content,
  accentColor,
  detailsLink,
  actionButton,
}: Props) {
  let css = useStyles();
  return (
    <Paper classes={{ root: css.paper }} data-testid={name}>
      <div className={css.title_row}>
        <ToolkitTitle title={name} />
      </div>

      <div className={css.header_row}>{header}</div>

      <div className={css.content_row}>
        <Paper classes={{ root: css.content_paper }}>
          <div className={css.subheader_row}>
            <Text variant="code" color={accentColor} className={css.subheader}>
              {subheader}
            </Text>
          </div>

          <ToolkitDivider color={accentColor} />

          <div>{content}</div>

          {detailsLink && (
            <div className={css.details_link}>
              <ExtLink to={detailsLink} target="_self">
                <Text variant="link">View Details</Text>
              </ExtLink>
            </div>
          )}
        </Paper>
      </div>

      <div>{actionButton}</div>
    </Paper>
  );
}

export default memo<Props>(ToolkitFullCard);
