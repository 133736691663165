// @flow
import React, { memo } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  Checkbox,
} from '@material-ui/core';
import ApiIcon from '@material-ui/icons/DeviceHub';
import SdkIcon from '@dt/material-components/svg/sdk.svg';
import CloudIcon from '@material-ui/icons/Cloud';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import { Text } from '@dt/material-components';
import { ThirdPartyAssetTypeGroupEnumValues } from '@dt/graphql-support/types';

const useStyles = makeStyles(theme => ({
  mainList: {
    maxHeight: 350,
    overflow: 'auto',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

type Props = {
  selectedList: Array<?string>,
  onChange: (Array<?Object>) => void,
};

const TableFiltersAssetTypeGroup = ({ selectedList, onChange }) => {
  const classes = useStyles();

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <List className={classes.mainList} component="nav">
      <ListItem
        button
        dense
        selected={selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.API)}
        onClick={() => handleChange(ThirdPartyAssetTypeGroupEnumValues.API)}
      >
        <Checkbox
          checked={Boolean(
            selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.API),
          )}
        />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <ApiIcon style={{ fontSize: 16, fill: palette.gray20 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} variant={'body'} noWrap>
          Third Party APIs
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        selected={selectedList.includes(
          ThirdPartyAssetTypeGroupEnumValues.MOBILE_SDK,
        )}
        onClick={() =>
          handleChange(ThirdPartyAssetTypeGroupEnumValues.MOBILE_SDK)
        }
      >
        <Checkbox
          checked={Boolean(
            selectedList.includes(
              ThirdPartyAssetTypeGroupEnumValues.MOBILE_SDK,
            ),
          )}
        />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <img src={SdkIcon} height={16} alt="mobile-sdk-icon" />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} variant={'body'} noWrap>
          Third Party OSS/SDKs
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        selected={selectedList.includes(
          ThirdPartyAssetTypeGroupEnumValues.CLOUD_RESOURCE,
        )}
        onClick={() =>
          handleChange(ThirdPartyAssetTypeGroupEnumValues.CLOUD_RESOURCE)
        }
      >
        <Checkbox
          checked={Boolean(
            selectedList.includes(
              ThirdPartyAssetTypeGroupEnumValues.CLOUD_RESOURCE,
            ),
          )}
        />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <CloudIcon style={{ fontSize: 16, fill: palette.gray20 }} />
          </Avatar>
        </ListItemIcon>

        <Text component={'div'} variant={'body'} noWrap>
          Third Party Cloud Resource
        </Text>
      </ListItem>
    </List>
  );
};

export default memo<Props>(TableFiltersAssetTypeGroup);
