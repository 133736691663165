// @flow
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

type Props = {
  value: boolean,
  onChange: (value: boolean) => mixed,
  label: string,
  ...
};
const FilterToggle = ({ value, onChange, label }: Props) => (
  <FormControlLabel
    control={
      <Switch
        checked={value}
        color="primary"
        onChange={e => {
          const value = Boolean(e.target.checked);
          onChange(value);
        }}
      />
    }
    label={label}
  />
);

export default FilterToggle;
