//@flow
import React, { useState, useEffect, memo } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
  Link,
  DialogTitle,
  Dialog,
  Select,
  Tooltip,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import copy from '@dt/components/src/copyToClipboard.util';
import Banner from './Banner';
import { Link as RouterLink } from '@reach/router';
import type { Node } from 'react';
import type { ApolloError } from '@apollo/client';
import { getInvariantUserAccount } from '@dt/session';

function TooltipWrap({ children, showTooltip }) {
  if (showTooltip) {
    return (
      <Tooltip title={'This feature has been disabled'}>
        <span>{children}</span>
      </Tooltip>
    );
  }

  return children;
}

function ConfirmScreen({
  isLoading,
  onCancel,
  onComplete,
}: {
  +isLoading: boolean,
  +onCancel: () => void,
  +onComplete: (expirationInDays: number) => Promise<void>,
}) {
  const [expiration, setExpiration] = useState<number>(7);

  let max_days = 0;
  try {
    const { accountInfo } = getInvariantUserAccount();
    max_days = Number(accountInfo.secure_share_link_max_days);
  } catch (error) {
    max_days = 0;
  }

  return (
    <>
      <DialogContent component="div">
        <Typography variant="body2" component="div">
          <span id="select-expiration">This link will expire in </span>
          <Select labelId="select-expiration" value={expiration} onChange={e => setExpiration(Number(e.target.value))}>
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={15}>15 days</MenuItem>
            <MenuItem value={30}>30 days</MenuItem>
            {max_days < 40 && max_days >= 37 && <MenuItem value={37}>37 days</MenuItem>}
            {max_days >= 60 && <MenuItem value={60}>60 days</MenuItem>}
            {max_days >= 90 && <MenuItem value={90}>90 days</MenuItem>}
            {max_days >= 100 && <MenuItem value={100}>100 days</MenuItem>}
            {max_days >= 180 && <MenuItem value={180}>180 days</MenuItem>}
            {max_days >= 365 && <MenuItem value={180}>365 days</MenuItem>}
          </Select>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => void onComplete(expiration)}
          color="primary"
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={15} />}
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
}

function SuccessScreen({
  link,
  manageShareLink,
  onClose,
  useReachRouter = false,
}: {
  +link: string,
  +manageShareLink?: ?string,
  +onClose: () => void,
  useReachRouter?: boolean,
}) {
  function onCopy() {
    copy(link);
    onClose();
  }
  return (
    <>
      <DialogContent>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
          value={link}
          size="small"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {manageShareLink && useReachRouter ? (
          <RouterLink to={manageShareLink} underline="none">
            <Button>Manage Links</Button>
          </RouterLink>
        ) : manageShareLink ? (
          <Link to={manageShareLink} underline="none">
            <Button>Manage Links</Button>
          </Link>
        ) : null}
        <Button onClick={onCopy} color="primary" variant="contained">
          Copy Link & Close
        </Button>
      </DialogActions>
    </>
  );
}

type Props = {|
  +loading?: boolean,
  +error?: ApolloError,
  +title: string,
  +variant?: 'text' | 'outlined' | 'contained',
  +size?: 'small' | 'medium' | 'large',
  +manageShareLink?: ?string,
  +renderButton?: ?(toggleDialog: () => void, disabled: boolean) => Node,
  +children?: Node,
  +onCreate: (expiration: number) => Promise<string>,
  useReachRouter?: boolean,
|};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Common implementation details for `SharedLinksButton*` components.
 *
 * @param title - Share dialog custom title
 * @param size - Size of the button
 * @param loading - Optional loading state passed by the caller
 * @param error - Optional ApolloError passed by the caller
 * @param manageShareLink - Route to the page where shared links are managed
 * @param renderButton - @deprecated Render a different share button. Pass in button props instead like size, variant, etc.
 * @param children - Share dialog custom content
 * @param onCreate - Workflow implemented by `SharedLinksButton*` to create a particular shared link type
 */
const SharedLinksButtonCommonComponent = function SharedLinksButtonCommon({
  loading,
  error,
  title,
  variant,
  size,
  manageShareLink,
  renderButton,
  children,
  onCreate,
  useReachRouter = false,
}: Props) {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [localError, setLocalError] = useState<string>('');

  let max_days = undefined;
  try {
    const { accountInfo } = getInvariantUserAccount();
    max_days = Number(accountInfo.secure_share_link_max_days);
  } catch (error) {
    max_days = undefined;
  }

  useEffect(() => {
    error && setLocalError(error?.message);
  }, [error]);

  useEffect(() => {
    // This is in the clean up to avoid cleaning up too early
    // while the material-ui animation is still going on.
    return () => {
      if (!isDialogOpen) {
        setLink('');
        setLoading(false);
        setLocalError('');
      }
    };
  }, [isDialogOpen]);

  return (
    <>
      <TooltipWrap showTooltip={max_days === 0}>
        <>
          {renderButton ? (
            renderButton(() => setDialogOpen(isDialogOpen => !isDialogOpen), max_days === 0)
          ) : (
            <Button
              onClick={() => setDialogOpen(isDialogOpen => !isDialogOpen)}
              variant={variant || 'contained'}
              size={size || 'small'}
              startIcon={<ShareIcon />}
              disabled={max_days === 0}
            >
              Secure Share
            </Button>
          )}
        </>
      </TooltipWrap>

      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="create-share-link"
        open={isDialogOpen}
        maxWidth={'md'}
      >
        <DialogTitle>
          <span id="create-share-link">{title}</span>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {localError && (
          <DialogContent>
            <Banner status="critical" inline heading="Error">
              {localError}
            </Banner>
          </DialogContent>
        )}
        {!link ? (
          <ConfirmScreen
            isLoading={loading || isLoading}
            onCancel={() => setDialogOpen(false)}
            onComplete={async (expirationInDays: number) => {
              setLoading(true);
              try {
                // Create shared link type.
                const link = await onCreate(
                  // validity_duration is in minutes. Multiply by 24 to transform days into hours, then by 60 to transform hours into minutes.
                  expirationInDays * 24 * 60,
                );

                setLoading(false);
                setLink(link);
              } catch (err) {
                setLocalError(
                  error?.message ||
                    'There was an error while sharing this page. Please contact support@datatheorem.com or try again later.',
                );
              }
            }}
          />
        ) : (
          <SuccessScreen
            link={link}
            onClose={() => setDialogOpen(false)}
            manageShareLink={manageShareLink}
            useReachRouter={useReachRouter}
          />
        )}
      </Dialog>
    </>
  );
};

export const SharedLinksButtonCommon = memo<Props>(SharedLinksButtonCommonComponent);
