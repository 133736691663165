//@flow
import React from 'react';
import { TextField } from '@material-ui/core';

type Props = {
  +value: string,
  +onChange: (value: string) => void,
  +onSubmit: () => void,
};

/*
 * Popout filter plugin.
 * User can type minimum age.
 *
 * @param value - Current state.
 * @param onChange - State change.
 * @param onSubmit - Triggered by user enter key.
 */
export const PopoutFilterPluginMinimumAge = ({
  value,
  onChange,
  onSubmit,
}: Props) => {
  return (
    <TextField
      fullWidth
      autoFocus
      type="number"
      value={value}
      onChange={e => onChange(e.target.value)}
      label="Enter Minimum Age (days)"
      onKeyDown={({ keyCode }: SyntheticKeyboardEvent<>) => {
        if (keyCode === 13) {
          onSubmit();
        }
      }}
    />
  );
};
