//@flow
import React from 'react';

import { SvgIcon, Typography } from '@material-ui/core';

import ImageIcon from '@material-ui/icons/InsertPhoto';
import XHRIcon from '@material-ui/icons/SettingsEthernet';
import StyleIcon from '@material-ui/icons/Style';
import FontIcon from '@material-ui/icons/FontDownload';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import PuzzleIcon from '@material-ui/icons/Extension';
import SocketIcon from '@material-ui/icons/ImportExport';
import CodeIcon from '@material-ui/icons/Code';

import { WebRequestTypeEnum } from '@dt/horizon-api';

import { palette } from '@dt/theme';

import type { WebRequestType } from '@dt/horizon-api';

export const WebApplicationNetworkActivityIcon = (props: {
  network_activity_type: WebRequestType,
  ...
}) => {
  const activity_type = props.network_activity_type;

  const label =
    ({
      [WebRequestTypeEnum.FETCH]: 'Fetch calls',
      [WebRequestTypeEnum.IMAGE]: 'Images download',
      [WebRequestTypeEnum.FONT]: 'Fonts download',
      [WebRequestTypeEnum.SCRIPT]: 'Scripts download',
      [WebRequestTypeEnum.STYLESHEET]: 'Stylesheets download',
      [WebRequestTypeEnum.XHR]: 'XHR calls',
      [WebRequestTypeEnum.MANIFEST]: 'Manifest download',
      [WebRequestTypeEnum.TEXTTRACK]: 'Texts download',
      [WebRequestTypeEnum.WEBSOCKET]: 'Websocket connection',
      [WebRequestTypeEnum.MEDIA]: 'Media download',
      [WebRequestTypeEnum.OTHER]: 'Unknown',
      [WebRequestTypeEnum.DOCUMENT]: 'Documents download',
      [WebRequestTypeEnum.EVENTSOURCE]: 'Event source',
    }: { +[WebRequestType]: string, ... })[activity_type] || 'Assets download';

  const Icon =
    ({
      [WebRequestTypeEnum.FETCH]: XHRIcon,
      [WebRequestTypeEnum.SCRIPT]: CodeIcon,
      [WebRequestTypeEnum.STYLESHEET]: StyleIcon,
      [WebRequestTypeEnum.IMAGE]: ImageIcon,
      [WebRequestTypeEnum.FONT]: FontIcon,
      [WebRequestTypeEnum.XHR]: XHRIcon,

      [WebRequestTypeEnum.MANIFEST]: DocumentIcon,
      [WebRequestTypeEnum.TEXTTRACK]: DocumentIcon,
      [WebRequestTypeEnum.WEBSOCKET]: SocketIcon,
      [WebRequestTypeEnum.MEDIA]: ImageIcon,
      [WebRequestTypeEnum.OTHER]: PuzzleIcon,
      [WebRequestTypeEnum.DOCUMENT]: DocumentIcon,
      [WebRequestTypeEnum.EVENTSOURCE]: PuzzleIcon,
    }: { +[WebRequestType]: typeof SvgIcon, ... })[activity_type] || ImageIcon;

  return (
    <div
      style={{
        padding: '0 10px',
        margin: '8px 10px 5px 0',
        height: 24,
        borderRadius: 5,
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: palette.gray45,
        color: palette.gray20,
      }}
    >
      <Icon
        style={{
          marginRight: 5,
          color: palette.gray30,
          width: 18,
          height: 18,
        }}
      />
      <Typography variant="caption" component="span">
        {label}
      </Typography>
    </div>
  );
};
