// @flow
import React from 'react';
import {
  type PolicyComplianceStandardType,
  type PolicyComplianceReferenceListType,
} from '@dt/horizon-api';
import {
  getIconFromComplianceStandard,
  getDisplayTextFromComplianceStandard,
  getDisplayTextFromComplianceStandardCriteria,
} from './util';
import { palette } from '@dt/theme';
import { Tooltip, Typography } from '@material-ui/core';

type OwnProps = {|
  complianceStandard: PolicyComplianceStandardType,
  complianceReferences: PolicyComplianceReferenceListType,
|};

type Props = {| ...OwnProps |};

const MultiComplianceTag = ({
  complianceStandard,
  complianceReferences,
}: Props) => {
  const tooltipText = (
    <>
      <strong>
        {getDisplayTextFromComplianceStandard(complianceStandard)} -{' '}
        {complianceReferences.length} Reference
        {complianceReferences.length > 1 ? 's' : ''}
      </strong>
      <br />
      {complianceReferences.map(r => (
        <div key={r.compliance_standard_criteria}>
          <br />
          {getDisplayTextFromComplianceStandardCriteria(
            r.compliance_standard_criteria,
          )}
          <hr />
          {r.description}
        </div>
      ))}
    </>
  );

  return (
    <Tooltip interactive title={tooltipText}>
      <div
        style={{
          padding: '4px 6px',
          backgroundColor: palette.gray45,
          borderRadius: 4,
          margin: '8px 4px 0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={getIconFromComplianceStandard(complianceStandard)}
          style={{ height: '14px', width: '14px', marginRight: 4 }}
        />
        <Typography variant="caption">
          {getDisplayTextFromComplianceStandard(complianceStandard)}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default React.memo<OwnProps>(MultiComplianceTag);
