//@flow
import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';

export default class InquireButton extends PureComponent<{
  onClickInquire: (e: SyntheticMouseEvent<HTMLElement>) => void,
}> {
  render() {
    return (
      <Button
        variant="outlined"
        color="primary"
        aria-label="Upgrade"
        onClick={this.props.onClickInquire}
      >
        Upgrade
      </Button>
    );
  }
}
