//@flow
import React, { type ElementConfig } from 'react';
import { SvgIcon } from '@material-ui/core';

/* 
This icon is part of the material icons set, but will not be included in a development release until material UI 5.0
See: https://github.com/mui-org/material-ui/pull/21498#issuecomment-652110636

This svg path copied from 
https://github.com/mui-org/material-ui/blob/9e0e31a3b2d6ccde644e6fe9b4eaecc6817e2833/packages/material-ui-icons/src/Handyman.js
*/

const ToolkitIcon = (props: { ...ElementConfig<typeof SvgIcon> }) => (
  <SvgIcon {...props}>
    <path d="M21.67 18.17l-5.3-5.3h-.99l-2.54 2.54v.99l5.3 5.3c.39.39 1.02.39 1.41 0l2.12-2.12c.39-.38.39-1.02 0-1.41z" />
    <path d="M17.34 10.19l1.41-1.41 2.12 2.12c1.17-1.17 1.17-3.07 0-4.24l-3.54-3.54-1.41 1.41V1.71l-.7-.71-3.54 3.54.71.71h2.83l-1.41 1.41 1.06 1.06-2.89 2.89-4.13-4.13V5.06L4.83 2.04 2 4.87 5.03 7.9h1.41l4.13 4.13-.85.85H7.6l-5.3 5.3c-.39.39-.39 1.02 0 1.41l2.12 2.12c.39.39 1.02.39 1.41 0l5.3-5.3v-2.12l5.15-5.15 1.06 1.05z" />
  </SvgIcon>
);

export default ToolkitIcon;
