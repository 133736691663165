//@flow
import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Text from './Text';
import { palette } from '@dt/theme';
import Skeleton from '@material-ui/lab/Skeleton';

type Props = {|
  color?: ?string,
  inline?: boolean,
  isLoading?: boolean,
  number: number | string,
  title: string,
|};

export default React.memo<Props>(function TotalCard(props) {
  const { color, inline, isLoading, number, title } = props;

  const content = (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
      spacing={2}
    >
      <Grid item>
        {isLoading ? (
          <Skeleton height={48} width={30} animation="wave" variant="rect" />
        ) : (
          <Text variant="numberXL" color={color || palette.gray20}>
            {number}
          </Text>
        )}
      </Grid>

      <Grid item>
        <Text
          variant="body"
          color={palette.gray20}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          {title}
        </Text>
      </Grid>
    </Grid>
  );

  return inline ? (
    content
  ) : (
    <Card
      style={{ minHeight: '160px', maxHeight: '160px' }}
      aria-label={`${number} ${title}`}
    >
      <CardContent style={{ padding: '16px' }}>{content}</CardContent>
    </Card>
  );
});
