//@flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { Text } from '@dt/material-components';
import type { Node, ElementConfig } from 'react';

type Props = {|
  +isLoading?: boolean,
  +title: Node,
  +style?: { ... },
  ...ElementConfig<typeof Typography>,
|};

const PageTitle = ({ isLoading, title, style = {} }: Props) => {
  // TODO@nw: Change the interface to *only* allow titles as strings.
  //          Then the following code can be used.
  // // Calculate the width the text will be after its done loading.
  // // Assumes that the provided text will be the same after loading :)
  // const canvas = document.createElement('canvas');
  // const context = canvas.getContext('2d');
  // context.font = `16px ${fontFamilies.primary}`;
  // // Unable to calculate height here.
  // // See https://stackoverflow.com/a/45789011/422312
  // const size = context.measureText(location.title);

  return isLoading ? (
    <Skeleton
      animation="pulse"
      variant="text"
      height={50}
      width={140}
      style={{ marginRight: 8 }}
    />
  ) : (
    <Text
      variant="titleL"
      color="inherit"
      style={{
        // TODO: Use correct design system variant for page titles.
        //       Keep "old" typography style from MUI until we can confirm that Text
        //       works for all pages.
        marginTop: 15,
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: '1.334',
        letterSpacing: '0em',
        marginBottom: '0.35em',
        ...style,
      }}
    >
      {title}
    </Text>
  );
};

export default PageTitle;
