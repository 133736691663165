// @flow
import React, { memo } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Tag from './Tag';

type Props = {
  value: string,
};

function TagRegion({ value }: Props) {
  return (
    <Tag
      icon={<LocationOnIcon style={{ width: 14, height: 14 }} />}
      label={`REGION: ${value}`}
    />
  );
}

export default memo<Props>(TagRegion);
