// @flow

import React, {
  memo,
  useEffect,
  useRef,
  useState,
  type Node,
  type Element,
} from 'react';
import { Grid, Paper, SvgIcon } from '@material-ui/core';
import { palette } from '@dt/theme';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Info from '@material-ui/icons/Info';
import Flag from '@material-ui/icons/Flag';
import Warning from '@material-ui/icons/Warning';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import Text from './Text';
import { makeStyles } from '@material-ui/core/styles';

export const StatusMessageEnumValues = Object.freeze({
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  CRITICAL: 'critical',
  PROCESSING: 'processing',
});

export type StatusMessageEnum = $Values<typeof StatusMessageEnumValues>;

type Props = {|
  children?: Node,
  status?: StatusMessageEnum,
  icon?: Element<typeof SvgIcon>,
  backgroundColor?: string,
  borderColor?: string,
  iconColor?: string,
  heading?: string,
  inline?: boolean,
|};

const statusColors = {
  success: {
    backgroundColor: palette.green50,
    border: palette.green,
    iconColor: palette.green10,
    defaultIcon: <ThumbUp />,
  },
  info: {
    backgroundColor: palette.white,
    border: palette.gray,
    iconColor: palette.gray10,
    defaultIcon: <Info />,
  },
  warning: {
    backgroundColor: palette.yellow50,
    border: palette.yellow,
    iconColor: palette.yellow10,
    defaultIcon: <Flag />,
  },
  critical: {
    backgroundColor: palette.red50,
    border: palette.red,
    iconColor: palette.red10,
    defaultIcon: <Warning />,
  },
  processing: {
    backgroundColor: palette.gray50,
    border: palette.gray,
    iconColor: palette.gray10,
    defaultIcon: <AutoRenewIcon />,
  },
};

const useStyles = makeStyles({
  ongoingIcon: {
    animation: '$rotation 4s infinite linear',
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
});

export default memo<Props>(function Banner(props: Props) {
  const iconElemRef = useRef(null);
  const [spacerWidth, setSpacerWidth] = useState();

  const setWidth = () => {
    setSpacerWidth(iconElemRef.current?.clientWidth || 0);
  };

  useEffect(() => {
    // set initial spacing
    setSpacerWidth(iconElemRef.current?.clientWidth || 0);
    window.addEventListener('resize', setWidth);

    return () => window.removeEventListener('resize', setWidth);
  }, []);

  const classes = useStyles();

  const config =
    (props.status && statusColors[props.status]) || statusColors.info;

  const iconToUse = props.icon || config.defaultIcon;

  return (
    <Paper
      style={{
        background: props.backgroundColor || config.backgroundColor,
        width: 'auto',
        borderTop: props.inline
          ? 'none'
          : `3px solid ${props.borderColor || config.border}`,
        padding: props.inline ? '10px' : '16px',
        display: 'flex',
        margin: props.inline ? '0' : '5px 0',
        alignItems: 'center',
      }}
      role="status"
      aria-labelledby="status-heading"
      aria-describedby="status-content"
      elevation={props.inline ? 0 : 1}
    >
      <Grid container direction="column">
        <Grid item container>
          {/* Icon */}
          <Grid
            item
            style={{
              paddingRight: props.inline ? '12px' : '16px',
              alignSelf: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'grid',
              }}
              ref={iconElemRef}
              id="banner-icon-div"
            >
              {React.cloneElement(iconToUse, {
                style: {
                  color: props.iconColor ? props.iconColor : config.iconColor,
                },
                className:
                  props.status === StatusMessageEnumValues.PROCESSING
                    ? classes.ongoingIcon
                    : null,
              })}
            </div>
          </Grid>

          {/* Heading */}
          <Grid
            item
            style={{
              alignSelf: 'center',
            }}
          >
            {props.heading && (
              <Text
                variant="body"
                style={{
                  color: palette.gray20,
                  marginTop: 0,
                  marginBottom: 0,
                }}
                label={props.heading}
              >
                {props.heading}
              </Text>
            )}
          </Grid>
        </Grid>

        {/* Children/Body */}
        {props.children ? (
          <Grid container item style={{ paddingLeft: spacerWidth + 12 }}>
            <Text
              variant="bodyS"
              style={{ color: palette.gray30, width: '100%' }}
              component="div"
            >
              {props.children}
            </Text>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
});
