// @flow
import { palette } from '@dt/theme';

export const container = {
  position: 'sticky',
  bottom: 0,
  right: 0,
  zIndex: 999,
};

export const modal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const paper = {
  background: 'white',
  minWidth: '60%',
  padding: 25,
};

export const root = {
  color: 'black',
  border: 0,
  borderRadius: 0,
  boxShadow: 'none',
  padding: '15px 20px',
  outline: 'none',
};

export const actions = {
  textAlign: 'right',
};

export const link = {
  marginLeft: 5,
  color: 'black',
  textDecoration: 'underline',
  fontSize: 15,
};

export const button = {
  color: 'white',
  hover: {
    background: '#fdb81e',
  },
  '& span': {
    fontSize: 9,
  },
};

export const icon = {
  color: palette.brand30,
  '& svg': {
    padding: 4,
    fontSize: 64,
  },
};

export const input = {};

export const dropzone = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minWidth: 400,
  minHeight: 180,
  '&:focus': {
    borderColor: '#2196f3',
  },
};

export const p = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const text = {};

export default {
  container,
  modal,
  paper,
  root,
  actions,
  link,
  button,
  icon,
  input,
  dropzone,
  p,
  text,
};
