//@flow
import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';

export default class PreviewButton extends PureComponent<{
  onPreviewClick: (e: SyntheticMouseEvent<HTMLElement>) => void,
}> {
  render() {
    return (
      <Button
        color="primary"
        variant="outlined"
        aria-label="Preview"
        onClick={this.props.onPreviewClick}
      >
        Preview
      </Button>
    );
  }
}
