// @flow
import React, { memo, useState } from 'react';
import { Button, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import TableFiltersChips from './components/chips/TableFiltersChips';
import TableFiltersPopper from './components/TableFiltersPopper';
import type {
  QueryAssets_Search_Filter_ValuesArgs,
  Scalars,
  AssetTypeEnum,
  HostedOn,
  DiscoveredVia,
  AssetOrderBy,
  FindingSeverity,
} from '@dt/graphql-support/types';

export type Filters = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_external_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_related_first_party_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_platform?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  filter_by_asset_group_id?:
    | ?Array<?$ElementType<Scalars, 'String'>>
    | ?$ElementType<Scalars, 'String'>,
  filter_by_vulnerable_to_toolkit_hacks?: ?Array<?$ElementType<
    Scalars,
    'String',
  >>,
  filter_by_active_protection_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_asset_tags?: ?$ElementType<Scalars, 'String'>,
  filter_by_is_shadow?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_app_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_uses_third_party_vendor_id?: ?Array<?$ElementType<
    Scalars,
    'String',
  >>,
  filter_by_asset_type_group?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<
    Scalars,
    'String',
  >>,
  filter_by_severity?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_mobile_app?: ?Array<?$ElementType<Scalars, 'String'>>,
  order_by?: ?AssetOrderBy,
  filter_by_discovered_application_keyword?: ?$ElementType<Scalars, 'String'>,
  filter_by_infringement_level?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_discovered_app_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_triaging_decision?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_severity?: ?Array<?FindingSeverity>,
  filter_by_has_policy_violations_with_status?: ?Array<?$ElementType<
    Scalars,
    'String',
  >>,
  filter_by_subscription?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_belongs_to_products?: ?Array<?$ElementType<Scalars, 'String'>>,
};

type Props = {
  assetsSearchFilterValuesQueryParams?: QueryAssets_Search_Filter_ValuesArgs,
  filterEnums: Array<string>,
  filters: Object,
  readOnlyFilters: Object,
  disabled: boolean,
  isMobilePhishing: boolean,
  isPolicyRules: boolean,
  onApply: Object => void,
  filterButtonName?: string,
};

let useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 9999,
    marginTop: theme.spacing(1),
    minWidth: 700,
  },

  // using the `gap` property prevents the layout shift that occurs due to the
  // filter chip having extra spacing around it. however, the `Box` component in
  // MUI v4 doesn't support `gap` as a style prop, so we need to create our own
  // styles to use it
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: `${theme.spacing(1)}px`,
  },
}));

const TableFilters = ({
  assetsSearchFilterValuesQueryParams,
  filterEnums,
  filters,
  readOnlyFilters,
  disabled,
  onApply,
  isMobilePhishing,
  isPolicyRules,
  filterButtonName,
}) => {
  const classes = useStyles();
  const [popperAnchor, setPopperAnchor] = useState<?HTMLInputElement>(null);

  return (
    <>
      <Box className={classes.wrapper}>
        <Button
          variant="contained"
          size="small"
          startIcon={<FilterIcon />}
          aria-describedby={'filter-popover'}
          onClick={event => setPopperAnchor(event.currentTarget)}
          disabled={disabled}
        >
          {filterButtonName || 'Filter Assets'}
        </Button>
        <TableFiltersChips
          filters={filters}
          readOnlyFilters={readOnlyFilters}
          onResetFilter={onApply}
        />
      </Box>

      {/* Filter Box */}
      <Popper
        id={'filter-popover'}
        open={Boolean(popperAnchor)}
        anchorEl={popperAnchor}
        placement={'bottom-start'}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={() => setPopperAnchor(null)}>
          <Paper>
            <TableFiltersPopper
              filterEnums={filterEnums}
              filters={filters}
              isMobilePhishing={isMobilePhishing}
              isPolicyRules={isPolicyRules}
              assetsSearchFilterValuesQueryParams={
                assetsSearchFilterValuesQueryParams
              }
              readOnlyFilters={readOnlyFilters}
              onClose={() => setPopperAnchor(null)}
              onApply={onApply}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default memo<Props>(TableFilters);
