// @flow
import React, { memo, useMemo, useState } from 'react';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import { Waypoint } from 'react-waypoint';
import debounce from 'lodash/fp/debounce';
import {
  TextField,
  ClickAwayListener,
  Popover,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorState from '../containers/ErrorState';
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
import type {
  AssetTag as AssetTagType,
  AssetTagKeyListQuery,
  AssetTagKeyListQueryVariables,
} from '@dt/graphql-support/types';

type AssetTag = { ...$Diff<AssetTagType, { __typename?: ?string }> };

type Props = {
  assetTag: AssetTag,
  assetTagValidationMessage?: string,
  onUpdate: AssetTag => void,
  disabled?: boolean,
};

const AssetTagsEditDialogAssetTagRowKey = ({
  assetTag,
  assetTagValidationMessage,
  onUpdate,
  disabled,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<?HTMLInputElement>(null);
  const handleActionClose = () => setAnchorEl(null);

  const [
    fetchAssetTagKeysQuery,
    { data, error, loading, fetchMore },
  ] = useLazyQuery<AssetTagKeyListQuery, AssetTagKeyListQueryVariables>(
    asset_tags.list,
    {
      fetchPolicy: 'network-only',
    },
  );

  const onTextFieldChange = useMemo(
    () =>
      debounce(500)((text: string) => {
        fetchAssetTagKeysQuery({
          variables: {
            filter_by_text: text,
          },
        });
      }),
    [fetchAssetTagKeysQuery],
  );

  const onSelectAssetTagKey = (assetTagKey: AssetTag) => {
    onUpdate(assetTagKey);
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        label="Key"
        placeholder="Enter asset tag"
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        required
        value={assetTag.tag}
        onChange={e => {
          onUpdate({
            ...assetTag,
            tag: e.target.value,
          });
          if (e.target.value) {
            setAnchorEl(e.currentTarget);
            onTextFieldChange(e.target.value);
          }
        }}
        disabled={disabled}
        error={Boolean(assetTagValidationMessage)}
        helperText={assetTagValidationMessage || null}
      />
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Popover
          id={'inventory-edit-action'}
          open={(loading || error || Boolean(data)) && Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleActionClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <MenuList
            style={{
              maxHeight: 400,
              overflow: 'auto',
              width: 250,
              paddingBottom: 0,
            }}
          >
            {error ? (
              <ErrorState error={error} />
            ) : loading ? (
              [...new Array(6)].map((_, key) => (
                <MenuItem dense key={key}>
                  <Skeleton animation="wave" width={`100%`} height={30} />
                </MenuItem>
              ))
            ) : (
              data?.asset_tag_key_list.asset_tag_keys.map(
                (key, idx) =>
                  !key.imported_from && (
                    <MenuItem
                      key={idx}
                      onClick={() =>
                        onSelectAssetTagKey({
                          id: key.id,
                          tag_id: key.id,
                          tag: key.name,
                        })
                      }
                    >
                      {key.name}
                    </MenuItem>
                  ),
              )
            )}
            {!loading &&
            !error &&
            data &&
            !data?.asset_tag_key_list.asset_tag_keys.find(
              key => key.name === assetTag.tag,
            ) ? (
              <MenuItem onClick={() => onSelectAssetTagKey(assetTag)}>
                {assetTag.tag} (new tag key)
              </MenuItem>
            ) : null}
            {fetchMore && (
              <Waypoint
                onEnter={() => {
                  if (fetchMore) {
                    fetchMore();
                  }
                }}
              >
                <MenuItem dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </MenuItem>
              </Waypoint>
            )}
          </MenuList>
        </Popover>
      </ClickAwayListener>
    </>
  );
};

export default memo<Props>(AssetTagsEditDialogAssetTagRowKey);
