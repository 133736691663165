// @flow
import React, { memo } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  Checkbox,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import FlagIcon from '@material-ui/icons/AssistantPhoto';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import { Text } from '@dt/material-components';
import type { FindingSeverity } from '@dt/graphql-support/types';

const useStyles = makeStyles(theme => ({
  mainList: {
    maxHeight: 350,
    overflow: 'auto',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

type Props = {
  selectedList: Array<?FindingSeverity>,
  onChange: (Array<?Object>) => void,
};

const TableFiltersSeverity = ({ selectedList, onChange }) => {
  const classes = useStyles();

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <List className={classes.mainList} component="nav">
      <ListItem
        button
        dense
        selected={selectedList.includes('HIGH')}
        onClick={() => handleChange('HIGH')}
      >
        <Checkbox checked={Boolean(selectedList.includes('HIGH'))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar
            style={{ height: 24, width: 24, backgroundColor: palette.red50 }}
          >
            <WarningIcon style={{ fontSize: 16, fill: palette.red10 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} variant={'body'} noWrap>
          High
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        selected={selectedList.includes('MEDIUM')}
        onClick={() => handleChange('MEDIUM')}
      >
        <Checkbox checked={Boolean(selectedList.includes('MEDIUM'))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar
            style={{ height: 24, width: 24, backgroundColor: palette.yellow50 }}
          >
            <FlagIcon style={{ fontSize: 16, fill: palette.yellow10 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} variant={'body'} noWrap>
          Medium
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        selected={selectedList.includes('LOW')}
        onClick={() => handleChange('LOW')}
      >
        <Checkbox checked={Boolean(selectedList.includes('LOW'))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar
            style={{ height: 24, width: 24, backgroundColor: palette.blue50 }}
          >
            <InfoIcon style={{ fontSize: 16, fill: palette.blue10 }} />
          </Avatar>
        </ListItemIcon>

        <Text component={'div'} variant={'body'} noWrap>
          Low
        </Text>
      </ListItem>
    </List>
  );
};

export default memo<Props>(TableFiltersSeverity);
