//@flow
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MarkdownIt from 'markdown-it';
import { palette, fontFamilies } from '@dt/theme';

const markdown = new MarkdownIt();

const defaultRender =
  markdown.renderer.rules.link_open ||
  function(tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

markdown.renderer.rules.link_open = function(tokens, idx, options, env, self) {
  let aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']);
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank';
  }

  return defaultRender(tokens, idx, options, env, self);
};

type Props = {|
  text?: ?string,
  inline?: boolean,
  defaultCodeBlock?: boolean,
|};

const styles = {
  position: 'relative',
  lineHeight: '1.4',

  '& p': {
    margin: 8,
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
  },

  '& code': {
    color: palette.gray30,
    backgroundColor: props => (props.noBackground ? 'transparent' : palette.gray50),
    fontFamily: fontFamilies.mono,
    fontSize: '0.9em',
    wordWrap: 'break-word',
    hyphens: 'auto',
    overflowX: 'auto',
    overflowY: 'visible',
    padding: '4px',
  },

  '& a': {
    color: palette.brand40,
    textDecoration: 'underline',
  },

  '& blockquote': {
    margin: 0,
    paddingLeft: 16,
    paddingTop: 2,
    paddingBottom: 2,
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: 3,
      backgroundColor: palette.gray45,
      borderRadius: 4,
      height: '100%',
    },
  },

  '& img': {
    maxWidth: '100%',
    objectFit: 'cover',
  },
};

const useStyles = makeStyles({
  // Be extremly cautious about updating this component, there are a lot of places markdown is rendered.
  markdown: {
    '& pre': {
      padding: '16px',
      overflow: 'auto',
      fontSize: '0.9em',
      lineHeight: '1.4',
      whiteSpace: 'pre-wrap',
      backgroundColor: palette.gray50,
      borderRadius: '3px',
      '& > code': {
        backgroundColor: 'transparent',
        color: palette.gray10,
        padding: 'initial',
      },
    },

    ...styles,
  },

  markdownWithAltCodeStyling: {
    '& pre': {
      padding: '16px',
      overflow: 'auto',
      fontSize: '0.9em',
      lineHeight: '1.4',
      whiteSpace: 'pre-wrap',
      backgroundColor: palette.black,
      borderRadius: '3px',
      '& > code': {
        backgroundColor: 'black',
        color: palette.white,
        padding: 'initial',
      },
    },

    ...styles,
  },
});

/*
There are 3 use cases for this component:

  1. Block level contents
  2. Inline contents on a single line
  3. Inline contents on multiline

When using the inline single line format make sure
your containing element has the following styles:

```css
white-space: nowrap
overflow: hidden
text-overflow: ellipsis
```

See the fixture file for all use-cases.
 */
function Markdown(props: Props) {
  const { text, inline, defaultCodeBlock = true, ...other } = props;
  const classes = useStyles(props);

  if (!text) return null;

  return inline ? (
    <span
      {...other}
      className={defaultCodeBlock ? classes.markdown : classes.markdownWithAltCodeStyling}
      dangerouslySetInnerHTML={{ __html: markdown.renderInline(text) }}
    />
  ) : (
    <div
      {...other}
      className={defaultCodeBlock ? classes.markdown : classes.markdownWithAltCodeStyling}
      dangerouslySetInnerHTML={{ __html: markdown.render(text) }}
    />
  );
}

export default memo<Props>(Markdown);
