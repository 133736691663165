// @flow
import React, { useState, memo, useCallback, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';

let assets = [
  { value: 'open_s3_buckets', label: 'Open S3 Buckets' },
  { value: 'google_cloud_storage', label: 'Google Cloud Storage' },
  { value: 'firebase_storage', label: 'Firebase Storage' },
  { value: 'azure_blobs', label: 'Azure Blobs' },
  { value: 'elasticsearch_databases', label: 'Elasticsearch databases' },
  { value: 'docker_registries', label: 'Docker Registries' },
];

let useStyles = makeStyles({
  form_control_root: {
    // minHeight: '200px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  form_control_label: {
    width: '360px',
    marginLeft: '0px',
    marginRight: '0px',
    padding: '2px',
  },
  checkbox_root: {
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
});

type Props = {
  defaultSelected?: Array<string>,
  onChange?: (Array<string>) => void,
};

type State = {
  [k: string]: boolean,
  ...,
};

function AssetSelector({ defaultSelected = [], onChange }) {
  let css = useStyles();
  let selectedMap = Object.fromEntries(defaultSelected.map(d => [d, true]));
  let [selected, setSelected] = useState<State>(selectedMap);

  useEffect(() => {
    if (onChange) {
      let selectedKeys = Object.keys(selected).filter(k =>
        Boolean(selected[k]),
      );
      onChange(selectedKeys);
    }
  }, [selected, onChange]);

  let handleChange = useCallback(value => {
    setSelected(s => ({ ...s, [value]: !s[value] }));
  }, []);

  return (
    <FormControl component="fieldset" classes={{ root: css.form_control_root }}>
      {assets.map(a => (
        <FormControlLabel
          key={a.value}
          classes={{ root: css.form_control_label }}
          control={
            <Checkbox
              disableRipple
              classes={{ root: css.checkbox_root }}
              size="small"
              color="primary"
              onChange={e => handleChange(e.target.value)}
              checked={Boolean(selected[a.value])}
              value={a.value}
            />
          }
          label={a.label}
        />
      ))}
    </FormControl>
  );
}

export default memo<Props>(AssetSelector);
