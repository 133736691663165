// @flow
import React, { memo } from 'react';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import type { Filters } from '../../TableFilters';
import { useParseAssetTagFilters } from '@dt/components';

type Props = {
  filters: Filters,
  readOnlyFilters: Filters,
  onResetFilter: Filters => void,
};

const FilterChip = withStyles({
  root: {
    background: palette.white,
    color: palette.gray20,
    border: `0.5px solid ${palette.gray20}`,
  },
  deleteIcon: {
    color: palette.gray20,
    '&:hover': {
      color: palette.gray30,
    },
  },
})(Chip);

const AssetFilterChips = ({ filters, readOnlyFilters, onResetFilter }) => {
  const assetTagsFilter = useParseAssetTagFilters(
    filters?.filter_by_asset_tags,
  );

  return (
    <>
      {filters.filter_by_text && (
        <FilterChip
          size={'small'}
          label={`keyword = ${filters.filter_by_text}`}
          disabled={Boolean(readOnlyFilters.filter_by_text)}
          onDelete={() => {
            const { filter_by_text, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_external_id && (
        <FilterChip
          size={'small'}
          label={`external ID = ${filters.filter_by_external_id}`}
          disabled={Boolean(readOnlyFilters.filter_by_external_id)}
          onDelete={() => {
            const { filter_by_external_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* asset type filter chip */}
      {filters.filter_by_asset_type && filters.filter_by_asset_type[0] && (
        <FilterChip
          size={'small'}
          label={`Asset Type =
            "${filters.filter_by_asset_type[0]?.split('_').join(' ') || ''}"
            ${(Number(filters.filter_by_asset_type?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_asset_type?.length) - 1) +
                ' more') ||
              ''}
          `}
          disabled={Boolean(readOnlyFilters.filter_by_asset_type)}
          onDelete={() => {
            const { filter_by_asset_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* asset type filter chip */}
      {filters.filter_by_related_first_party_asset_type &&
        filters.filter_by_related_first_party_asset_type[0] && (
          <FilterChip
            size={'small'}
            label={`Asset Type =
            "${filters.filter_by_related_first_party_asset_type[0]
              ?.split('_')
              .join(' ') || ''}"
            ${(Number(
              filters.filter_by_related_first_party_asset_type?.length,
            ) > 1 &&
              '+ ' +
                (Number(
                  filters.filter_by_related_first_party_asset_type?.length,
                ) -
                  1) +
                ' more') ||
              ''}
          `}
            disabled={Boolean(
              readOnlyFilters.filter_by_related_first_party_asset_type,
            )}
            onDelete={() => {
              const {
                filter_by_related_first_party_asset_type,
                ...rest
              } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}

      {/* discovered via filter chip */}
      {filters.filter_by_discovered_via && filters.filter_by_discovered_via[0] && (
        <FilterChip
          size={'small'}
          label={`Discovery Source =
            "${filters.filter_by_discovered_via[0].split('_').join(' ') || ''}"
            ${(Number(filters.filter_by_discovered_via?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_discovered_via?.length) - 1) +
                ' more') ||
              ''}
          `}
          disabled={Boolean(readOnlyFilters.filter_by_discovered_via)}
          onDelete={() => {
            const { filter_by_discovered_via, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* hosted on filter chip */}
      {filters.filter_by_cloud_provider && filters.filter_by_cloud_provider[0] && (
        <FilterChip
          size={'small'}
          label={`Hosted On =
            "${filters.filter_by_cloud_provider[0].split('_').join(' ') || ''}"
            ${(Number(filters.filter_by_cloud_provider?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_cloud_provider?.length) - 1) +
                ' more') ||
              ''}
          `}
          disabled={Boolean(readOnlyFilters.filter_by_cloud_provider)}
          onDelete={() => {
            const { filter_by_cloud_provider, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* asset group filter chip */}
      {filters.filter_by_asset_group_id && (
        <FilterChip
          size={'small'}
          label={`Asset Group ID = ${filters.filter_by_asset_group_id[0] || ''}
            ${(Number(filters.filter_by_asset_group_id?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_asset_group_id?.length) - 1) +
                ' more') ||
              ''}`}
          disabled={Boolean(readOnlyFilters.filter_by_asset_group_id)}
          onDelete={() => {
            const { filter_by_asset_group_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* Shadow Assets Filter Chip */}
      {filters.filter_by_is_shadow && (
        <FilterChip
          size={'small'}
          label={`shadow assets = on`}
          disabled={Boolean(readOnlyFilters.filter_by_is_shadow)}
          onDelete={() => {
            const { filter_by_is_shadow, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* Mobile platform */}
      {filters.filter_by_platform && (
        <FilterChip
          size={'small'}
          label={`Platform = ${filters.filter_by_platform[0] || ''}
            ${(Number(filters.filter_by_platform?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_platform?.length) - 1) +
                ' more') ||
              ''}`}
          disabled={Boolean(readOnlyFilters.filter_by_platform)}
          onDelete={() => {
            const { filter_by_platform, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_active_protection_status && (
        <FilterChip
          size={'small'}
          label={`Active Protection Status = ${filters
            .filter_by_active_protection_status[0] || ''}
            ${(Number(filters.filter_by_active_protection_status?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_active_protection_status?.length) -
                  1) +
                ' more') ||
              ''}`}
          disabled={Boolean(readOnlyFilters.filter_by_active_protection_status)}
          onDelete={() => {
            const { filter_by_active_protection_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_vulnerable_to_toolkit_hacks && (
        <FilterChip
          size={'small'}
          label={`Hack Status = ${filters
            .filter_by_vulnerable_to_toolkit_hacks[0] || ''}
            ${(Number(filters.filter_by_vulnerable_to_toolkit_hacks?.length) >
              1 &&
              '+ ' +
                (Number(filters.filter_by_vulnerable_to_toolkit_hacks?.length) -
                  1) +
                ' more') ||
              ''}`}
          disabled={Boolean(
            readOnlyFilters.filter_by_vulnerable_to_toolkit_hacks,
          )}
          onDelete={() => {
            const { filter_by_vulnerable_to_toolkit_hacks, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* Shadow Assets Filter Chip */}
      {assetTagsFilter.list()?.map((assetTag, idx) => (
        <FilterChip
          key={idx}
          size={'small'}
          label={`asset tag = ${assetTag.key}${
            assetTag.value ? ':' + assetTag.value : ''
          }`}
          disabled={Boolean(readOnlyFilters.filter_by_asset_tags)}
          onDelete={() => {
            const { filter_by_asset_tags, ...rest } = filters;
            onResetFilter({
              ...rest,
              filter_by_asset_tags: assetTagsFilter.remove(assetTag),
            });
          }}
        />
      ))}

      {/* Mobile Phishing Filters */}
      {filters.filter_by_discovered_application_keyword && (
        <FilterChip
          size={'small'}
          label={`Keyword = ${filters.filter_by_discovered_application_keyword}`}
          disabled={Boolean(
            readOnlyFilters.filter_by_discovered_application_keyword,
          )}
          onDelete={() => {
            const {
              filter_by_discovered_application_keyword,
              ...rest
            } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_infringement_level &&
        filters.filter_by_infringement_level[0] && (
          <FilterChip
            size={'small'}
            label={`Fraudulent behavior =
            "${filters.filter_by_infringement_level[0]?.split('_').join(' ') ||
              ''}"
            ${(Number(filters.filter_by_infringement_level?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_infringement_level?.length) - 1) +
                ' more') ||
              ''}
          `}
            disabled={Boolean(readOnlyFilters.filter_by_infringement_level)}
            onDelete={() => {
              const { filter_by_infringement_level, ...rest } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}

      {filters.filter_by_discovered_app_status &&
        filters.filter_by_discovered_app_status[0] && (
          <FilterChip
            size={'small'}
            label={`App status =
            "${filters.filter_by_discovered_app_status[0]
              ?.split('_')
              .join(' ') || ''}"
            ${(Number(filters.filter_by_discovered_app_status?.length) > 1 &&
              '+ ' +
                (Number(filters.filter_by_discovered_app_status?.length) - 1) +
                ' more') ||
              ''}
          `}
            disabled={Boolean(readOnlyFilters.filter_by_discovered_app_status)}
            onDelete={() => {
              const { filter_by_discovered_app_status, ...rest } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}

      {filters.filter_by_triaging_decision &&
        filters.filter_by_triaging_decision[0] && (
          <FilterChip
            size={'small'}
            label={`Triaging decision =
              "${filters.filter_by_triaging_decision[0]?.split('_').join(' ') ||
                ''}"
              ${(Number(filters.filter_by_triaging_decision?.length) > 1 &&
                '+ ' +
                  (Number(filters.filter_by_triaging_decision?.length) - 1) +
                  ' more') ||
                ''}
            `}
            disabled={Boolean(readOnlyFilters.filter_by_triaging_decision)}
            onDelete={() => {
              const { filter_by_triaging_decision, ...rest } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}

      {/* Policy Rules */}
      {filters.filter_by_severity && filters.filter_by_severity[0] && (
        <FilterChip
          size={'small'}
          label={`Severity =
                "${filters.filter_by_severity[0]?.split('_').join(' ') || ''}"
                ${(Number(filters.filter_by_severity?.length) > 1 &&
                  '+ ' +
                    (Number(filters.filter_by_severity?.length) - 1) +
                    ' more') ||
                  ''}
              `}
          disabled={Boolean(readOnlyFilters.filter_by_severity)}
          onDelete={() => {
            const { filter_by_severity, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_has_policy_violations_with_status &&
        filters.filter_by_has_policy_violations_with_status[0] && (
          <FilterChip
            size={'small'}
            label={`Policy Violation Status =
                  "${filters.filter_by_has_policy_violations_with_status[0]
                    ?.split('_')
                    .join(' ') || ''}"
                  ${(Number(
                    filters.filter_by_has_policy_violations_with_status?.length,
                  ) > 1 &&
                    '+ ' +
                      (Number(
                        filters.filter_by_has_policy_violations_with_status
                          ?.length,
                      ) -
                        1) +
                      ' more') ||
                    ''}
                `}
            disabled={Boolean(
              readOnlyFilters.filter_by_has_policy_violations_with_status,
            )}
            onDelete={() => {
              const {
                filter_by_has_policy_violations_with_status,
                ...rest
              } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}

      {/* Vendors Filter Chip */}
      {filters.filter_by_uses_third_party_vendor_id && (
        <FilterChip
          size={'small'}
          label={`Vendors = ${Number(
            filters.filter_by_uses_third_party_vendor_id?.length,
          )} vendors`}
          disabled={Boolean(
            readOnlyFilters.filter_by_uses_third_party_vendor_id,
          )}
          onDelete={() => {
            const { filter_by_uses_third_party_vendor_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* Web/Mobile Apps Filter Chip */}
      {filters.filter_by_related_first_party_asset_id && (
        <FilterChip
          size={'small'}
          label={`Web/Mobile Apps = ${Number(
            filters.filter_by_related_first_party_asset_id?.length,
          )} apps`}
          disabled={Boolean(
            readOnlyFilters.filter_by_related_first_party_asset_id,
          )}
          onDelete={() => {
            const { filter_by_related_first_party_asset_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {/* Third Party Asset Type Filter Chip */}
      {filters.filter_by_asset_type_group && (
        <FilterChip
          size={'small'}
          label={`Third Party Asset Type = ${Number(
            filters.filter_by_asset_type_group?.length,
          )} Asset Types`}
          disabled={Boolean(readOnlyFilters.filter_by_asset_type_group)}
          onDelete={() => {
            const { filter_by_asset_type_group, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_subscription && filters.filter_by_subscription[0] && (
        <FilterChip
          size={'small'}
          label={`Subscription =
                      "${filters.filter_by_subscription[0]
                        ?.split('_')
                        .join(' ') || ''}"
                      ${(Number(filters.filter_by_subscription?.length) > 1 &&
                        '+ ' +
                          (Number(filters.filter_by_subscription?.length) - 1) +
                          ' more') ||
                        ''}
                    `}
          disabled={Boolean(readOnlyFilters.filter_by_subscription)}
          onDelete={() => {
            const { filter_by_subscription, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_belongs_to_products &&
        filters.filter_by_belongs_to_products[0] && (
          <FilterChip
            size={'small'}
            label={`Products =
                    "${filters.filter_by_belongs_to_products[0]
                      ?.split('_')
                      .join(' ') || ''}"
                    ${(Number(filters.filter_by_belongs_to_products?.length) >
                      1 &&
                      '+ ' +
                        (Number(filters.filter_by_belongs_to_products?.length) -
                          1) +
                        ' more') ||
                      ''}
                  `}
            disabled={Boolean(readOnlyFilters.filter_by_belongs_to_products)}
            onDelete={() => {
              const { filter_by_belongs_to_products, ...rest } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}
    </>
  );
};

export default memo<Props>(AssetFilterChips);
