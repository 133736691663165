//@flow
import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import Text from '../Text';
import { palette } from '@dt/theme';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles } from './NotificationItem';

export default memo<{||}>(function NotificationItemError() {
  const styles = useStyles();

  return (
    <Grid
      container
      direction="row"
      className={styles.errorContainer}
      spacing={2}
    >
      <Grid item xs={1}>
        <ErrorIcon style={{ width: 20 }} />
      </Grid>
      <Grid item xs={11}>
        <Text color={palette.gray20} variant="helperText">
          Unable to fetch events. Try again
        </Text>
      </Grid>
    </Grid>
  );
});
