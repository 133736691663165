// @flow
import React, { memo, type Node } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

let useStyles = makeStyles({
  root: {
    padding: '0px',
    display: 'inline',
    verticalAlign: 'initial',
    textTransform: 'initial',
    fontSize: 'initial',
    minWidth: 'initial',
    lineHeight: 'initial',
    color: palette.blue20,
    '&:hover': {
      color: palette.blue10,
      backgroundColor: 'initial',
    },
  },
});

type Props = {
  children: Node,
  onClick?: () => void,
};

function TextButton({ children, onClick }: Props) {
  let css = useStyles();
  return (
    <Button
      disableRipple
      classes={{
        root: css.root,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default memo<Props>(TextButton);
